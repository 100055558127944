import React, { useEffect, useState } from "react";
// reactstrap components
import {

  Button,
  Row,
  Col,
  Card,
  CardHeader,
  Container
} from "reactstrap";
import { useNavigate } from 'react-router-dom';
import { get } from "../../utils/apiManager";

import { useLocation } from 'react-router-dom';
import moment from "moment"


const ViewOne = () => {

  const location = useLocation()
  const Navigate = useNavigate()
  const [company, setCompany] = useState({})

  useEffect(() => {
    if (location?.search) {

      getOneCompanies(location?.search?.split("?")[1])
    }
  }, [])


  const getOneCompanies = (id) => {
    get(`/company/${id}`).then((res) => {
      setCompany(res?.datas)

    }).catch((error) => {
      console.log(error, "erre");
    })
  }



  return (
    <>
      {/* <Header /> */}
      <div className="mx-5">

        <Row className="mt-7">

          <Col className="mb-5 mb-xl-0 " xl="6" >

            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0" style={{ textAlign: "left" }}>View Company</h3>
                    {/* <Button className='bi-trash justify-content-right' style={{
                      position
                        : "absolute", right: 0, marginRight: "40px"
                    }} onClick={() => Navigate(`/admin/user`)} color="primary">Back</Button> */}
                  </div>

                </Row>
              </CardHeader>
              <div style={{ textAlign: "left", marginLeft: "30px" }}>
                {company?.firstName && <> <p>Name : {company?.firstName ? company?.firstName + " " + company?.lastName : "-"}</p><br /></>}
                {company?.companyName && <> <p>Company Name : {company?.companyName}</p><br /></>}
                {company?.companyType && <><p>Company Type : {company?.companyType}</p><br /></>}
                {company?.email && <><p>Email : {company?.email}</p><br /></>}
                {company?.phone && <><p>Phone : {company?.phone}</p><br /></>}
                {company?.cellPhone && <><p>Cell Phone : {company?.cellPhone}</p><br /> </>}
                {company?.addressAptSuite && <> <p>address Apt Suite : {company?.addressAptSuite}</p><br /></>}
                {company?.city && <> <p>City : {company?.city}</p><br /></>}
                {company?.state && <> <p>State : {company?.state}</p><br /></>}
                {company?.website && <> <p>Website : {company?.website}</p><br /></>}
                {company?.address && <> <p>Address : {company?.address}</p><br /></>}
                {company?.zipCode && <> <p>Zip Code : {company?.zipCode}</p><br /></>}

                <p>Status :{company?.isActive ? <Button color="success" type="submit" className="ml-2">
                  Active
                </Button> : <Button color="danger" type="submit" className="ml-2">
                  Deactive
                </Button>}</p><br />

              </div>
            </Card>
          </Col>
          <Col className="mb-5 mb-xl-0 " xl="6" >
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0" style={{ textAlign: "left" }}>View Staff</h3>
                    <Button className='bi-trash justify-content-right' style={{
                      position
                        : "absolute", right: 0, marginRight: "40px"
                    }} onClick={() => Navigate(`/admin/user`)} color="primary">Back</Button>
                  </div>

                </Row>
              </CardHeader>
              <div style={{ textAlign: "left", marginLeft: "30px" }}>
                {company?.staffDetails?.name && <> <p>Name : {company?.staffDetails?.name ? company?.staffDetails?.name : "-"}</p><br /></>}
                {company?.staffDetails?.email && <> <p>Email : {company?.staffDetails?.email || ""}</p><br /></>}

                {company?.staffDetails?.phone && <><p>Phone : {company?.staffDetails?.phone || ""}</p><br /></>}
                {company?.staffDetails?.birthDate && <> <p>Birth Date : {company?.staffDetails?.birthDate ? moment(company?.staffDetails?.birthDate).format("DD MMMM YYYY") : ""}</p><br /></>}
                {company?.staffDetails?.position && <><p>Position : {company?.staffDetails?.position || ""}</p><br /> </>}
                {company?.staffDetails?.address && <> <p>address  : {company?.staffDetails?.address || ""}</p><br /></>}
                {company?.staffDetails?.city && <> <p>City : {company?.staffDetails?.city || ""}</p><br /></>}
                {company?.staffDetails?.state && <> <p>State : {company?.staffDetails?.state || ""}</p><br /></>}

                {company?.staffDetails?.zipCode && <> <p>Zip Code : {company?.staffDetails?.zipCode || ""}</p><br /></>}
                {company?.staffDetails?.notes && <> <p>Notes : {company?.staffDetails?.notes || ""}</p><br /></>}
                {company?.staffDetails?.others && <> <p>Others : {company?.staffDetails?.others || ''}</p><br /></>}


                <p>Status :{company?.staffDetails?.isActive ? <Button color="success" type="submit" className="ml-2">
                  Active
                </Button> : <Button color="danger" type="submit" className="ml-2">
                  Deactive
                </Button>}</p><br />

              </div>
            </Card>
          </Col>
          <Col className="mb-5 mb-xl-0 mt-2" xl="6" >
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0" style={{ textAlign: "left" }}>View Consignee</h3>
                    {/* <Button className='bi-trash justify-content-right' style={{
                      position
                        : "absolute", right: 0, marginRight: "40px"
                    }} onClick={() => Navigate(`/admin/user`)} color="primary">Back</Button> */}
                  </div>

                </Row>
              </CardHeader>
              <div style={{ textAlign: "left", marginLeft: "30px" }}>
                {company?.firstName && <> <p>Name : {company?.firstName ? company?.firstName + " " + company?.lastName : "-"}</p><br /></>}
                {company?.companyName && <> <p>Company Name : {company?.companyName}</p><br /></>}
                {company?.companyType && <><p>Company Type : {company?.companyType}</p><br /></>}
                {company?.email && <><p>Email : {company?.email}</p><br /></>}
                {company?.phone && <><p>Phone : {company?.phone}</p><br /></>}
                {company?.cellPhone && <><p>Cell Name : {company?.cellPhone}</p><br /> </>}
                {company?.addressAptSuite && <> <p>address Apt Suite : {company?.addressAptSuite}</p><br /></>}
                {company?.city && <> <p>City : {company?.city}</p><br /></>}
                {company?.state && <> <p>State : {company?.state}</p><br /></>}
                {company?.website && <> <p>Website : {company?.website}</p><br /></>}
                {company?.address && <> <p>Address : {company?.address}</p><br /></>}
                {company?.zipCode && <> <p>Zip Code : {company?.zipCode}</p><br /></>}

                <p>Status :{company?.isActive ? <Button color="success" type="submit" className="ml-2">
                  Active
                </Button> : <Button color="danger" type="submit" className="ml-2">
                  Deactive
                </Button>}</p><br />

              </div>
            </Card>
          </Col>
        </Row>
      </div >
    </>
  );

}

export default ViewOne