/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import UserHeader from "../components/Headers/UserHeader.js";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useRef } from "react";
import { get, post } from "../utils/apiManager.js";
import { baseURL } from "../config/config.js";
import NewPassword from "./newPassword.js";
import { toast } from "react-toastify";
import { LoginDetails } from "../reduxToolkit/slice/auth.js";

const Profile = () => {

  const { profileImage, userId } = useSelector(state => state.auth);
  let token = localStorage.getItem("token")
  const [isShowEditProfile, setIsShowEditProfile] = useState(true)
  const [profileName, setProfileName] = useState()
  const [profileEmail, setProfileEmail] = useState()
  const [profileImageUrl, setProfileImageUrl] = useState(profileImage)
  const [sendProfileImage, setSendProfileImage] = useState({})
  const [isProfileUpdate, setIsProfileUpdate] = useState(false)
  const [isChangePassword, setIsChangePassword] = useState(true)
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const dispatch = useDispatch()

  const inputRef = useRef();



  useEffect(() => {
    getOneProfile()
  }, [])


  const getOneProfile = () => {
    get(`/admin/profile/${token}`).then((res) => {
      setProfileName(res?.data?.name)
      setProfileEmail(res?.data?.email)
      setProfileImageUrl(res?.data?.profileImage)
      if (res?.data?.profileImage) {
        setProfileImageUrl(`${baseURL}/${res?.data?.profileImage}`)
        setIsProfileUpdate(false)

      }
    }).catch((error) => {
      console.log(error, "erre");
    })
  }

  const profileUpdateHandler = (e) => {


    const file = inputRef?.current?.files?.[0];
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result.split(',')[1]; // Get Base64 part only
        setProfileImageUrl(`data:image/png;base64,${base64String}`)
        setSendProfileImage(file)
        setIsProfileUpdate(true)
        setIsShowEditProfile(false)
      };

      reader.readAsDataURL(file);


    }

  }

  const updateProfile = () => {


    const Url = `${baseURL}/admin/profile/edit`
    let formData = new FormData();
    formData.append("name", profileName);
    formData.append("id", userId)
    if (isProfileUpdate) {
      formData.append("image", sendProfileImage)
    }
    formData?.append("isProfileUpdate", isProfileUpdate)
    post(Url, formData, 1).then((data) => {
      if (data?.status) {
        dispatch(LoginDetails(
          { name: profileName, isLogin: true, userId, token: token, profileImage: profileImage }
        ))
        toast.success(data?.message);
        setIsShowEditProfile(true)
      }
      else {

        toast.error(data?.message)
      }
    }).catch(err => {
      toast.error(err.response.data.error)

    })
  }

  const ChangePassword = () => {
    if (oldPassword == "") {
      toast.error("old Password is required")
      return
    }

    if (newPassword !== confirmPassword) {
      toast.error("new password and confirm password not some")
      return
    }

    const Url = `${baseURL}/admin/change-password`
    let newData = { oldPassword, newPassword, id: userId }
    post(Url, newData).then((data) => {
      if (data?.status) {

        toast.success(data?.message);
        setIsChangePassword(true)
        setOldPassword("")
        setNewPassword("")
        setConfirmPassword("")
      }
      else {

        toast.error(data?.message)
      }
    }).catch(err => {
      toast.error(err.response.data.error)

    })


  }




  return (
    <>
      <UserHeader isShowEditProfile={isShowEditProfile} profileName={profileName} profileEmail={profileEmail} setIsShowEditProfile={setIsShowEditProfile} sendProfileImage={sendProfileImage} isProfileUpdate={isProfileUpdate} setIsChangePassword={setIsChangePassword} isChangePassword={isChangePassword}
        oldPassword={oldPassword} newPassword={newPassword} confirmPassword={confirmPassword}
        setOldPassword={setOldPassword} setNewPassword={setNewPassword} setConfirmPassword={setConfirmPassword}

      />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            {/* <Card className="card-profile shadow"> */}
            <Row className="justify-content-center">
              <Col className="order-lg-2" lg="3">
                <div className="card-profile-image">
                  <input type="file" hidden ref={inputRef} onChange={profileUpdateHandler} />

                  <a onClick={(e) => { inputRef.current.click() }}>
                    <img
                      alt="..."
                      className="rounded-circle"
                      // src={require(}
                      src={profileImageUrl ? profileImageUrl : "../assets/img/theme/team-4-800x800.jpg"}
                    />


                  </a>

                </div>
              </Col>
            </Row>
            {/* <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                <div className="d-flex justify-content-between">
                  <Button
                    className="mr-4"
                    color="info"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="sm"
                  >
                    Connect
                  </Button>
                  <Button
                    className="float-right"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="sm"
                  >
                    Message
                  </Button>
                </div>
              </CardHeader> */}
            {/* <CardBody className="pt-0 pt-md-4">
                <Row>
                  <div className="col">
                    <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                      <div>
                        <span className="heading">22</span>
                        <span className="description">Friends</span>
                      </div>
                      <div>
                        <span className="heading">10</span>
                        <span className="description">Photos</span>
                      </div>
                      <div>
                        <span className="heading">89</span>
                        <span className="description">Comments</span>
                      </div>
                    </div>
                  </div>
                </Row>
                <div className="text-center">
                  <h3>
                    Jessica Jones
                    <span className="font-weight-light">, 27</span>
                  </h3>
                  <div className="h5 font-weight-300">
                    <i className="ni location_pin mr-2" />
                    Bucharest, Romania
                  </div>
                  <div className="h5 mt-4">
                    <i className="ni business_briefcase-24 mr-2" />
                    Solution Manager - Creative Tim Officer
                  </div>
                  <div>
                    <i className="ni education_hat mr-2" />
                    University of Computer Science
                  </div>
                  <hr className="my-4" />
                  <p>
                    Ryan — the name taken by Melbourne-raised, Brooklyn-based
                    Nick Murphy — writes, performs and records all of his own
                    music.
                  </p>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    Show more
                  </a>
                </div>
              </CardBody> */}
            {/* </Card> */}
          </Col>
          <Col className="order-xl-1" xl="6">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0 text-left">My Account</h3>
                  </Col>
                  {/* <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      Settings
                    </Button>
                  </Col> */}

                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  {/* <h6 className="heading-small text-muted mb-4">
                    Admin information
                  </h6> */}
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-name"
                          >
                            Name
                          </label>
                          <input
                            className="form-control"
                            id="input-name"
                            placeholder="Name"
                            type="text"
                            value={profileName}
                            onChange={(e) => setProfileName(e.target.value)}
                            disabled={isShowEditProfile}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email address
                          </label>
                          <input
                            className="form-control"
                            id="input-email"
                            placeholder="jesse@example.com"
                            type="email"
                            value={profileEmail}

                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Button
                        // color="info"
                        style={{ backgroundColor: "#2a2f59" }}
                        // style={{display:isShowEditProfile?"none":"block"}}
                        disabled={isShowEditProfile}
                        onClick={updateProfile}
                        className="text-white ml-lg-3"
                      >
                        Update Profile
                      </Button>
                    </Row>
                    {/* <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            First name
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue="Lucky"
                            id="input-first-name"
                            placeholder="First name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Last name
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue="Jesse"
                            id="input-last-name"
                            placeholder="Last name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row> */}
                  </div>
                  {/* <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    Contact information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-address"
                          >
                            Address
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue="Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09"
                            id="input-address"
                            placeholder="Home Address"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-city"
                          >
                            City
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue="New York"
                            id="input-city"
                            placeholder="City"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            Country
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue="United States"
                            id="input-country"
                            placeholder="Country"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            Postal code
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-postal-code"
                            placeholder="Postal code"
                            type="number"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">About me</h6>
                  <div className="pl-lg-4">
                    <FormGroup>
                      <label>About Me</label>
                      <Input
                        className="form-control-alternative"
                        placeholder="A few words about you ..."
                        rows="4"
                        defaultValue="A beautiful Dashboard for Bootstrap 4. It is Free and
                        Open Source."
                        type="textarea"
                      />
                    </FormGroup>
                  </div> */}
                </Form>
              </CardBody>

            </Card>
          </Col>
          <Col className="order-xl-1" xl="6">
            <Card className="bg-secondary shadow h-100">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0 text-left">Change Password</h3>
                  </Col>

                </Row>
              </CardHeader>
              <CardBody>
                <Form>

                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-name"
                          >
                            Old Password
                          </label>
                          <input
                            className="form-control"
                            placeholder="Old Password"
                            type="password"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            disabled={isChangePassword}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            New Password
                          </label>
                          <input
                            className="form-control"
                            type="password"
                            placeholder="New Password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            disabled={isChangePassword}

                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Confirm Password
                          </label>
                          <input
                            className="form-control"
                            type="password"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            disabled={isChangePassword}

                          />
                        </FormGroup>

                      </Col>
                      <Button
                        // color="primary"
                        style={{ backgroundColor: "#2a2f59" }}
                        disabled={isChangePassword}
                        onClick={ChangePassword}
                        className="text-white ml-lg-3"
                      >
                        Change Password
                      </Button>

                    </Row>

                  </div>

                </Form>
              </CardBody>

            </Card>
          </Col>
        </Row>

      </Container>
    </>
  );
};

export default Profile;
