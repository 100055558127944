
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
  } from "reactstrap";
  import { useForm } from "react-hook-form";
  import { baseURL } from '../config/config';
  import { post } from "../utils/apiManager";
  import { ToastContainer, toast } from 'react-toastify';
  import {Link,useNavigate,useParams} from 'react-router-dom';
  import { LoginDetails } from "../reduxToolkit/slice/auth";
  import { useDispatch, useSelector } from "react-redux";
  import {useEffect} from "react"
  
  
  const NewPassword = () => {
  
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const Navigate = useNavigate()
    const dispatch = useDispatch()
    const { id } = useParams();

  
    useEffect(()=>{
        if(!id){
            Navigate("/auth/forgot-password")
        }

    },[id])

    const onSubmit = (data) => {

        if(data?.newPassword != data?.oldPassword){
            toast.error("old password and new password does not match")
            return
        }
    
      const Url = `${baseURL}/admin/reset-password`
      let newData = {validateString:id,password:data?.newPassword}
      post(Url, newData).then((data) => handleLogin(data));
      const handleLogin = (data) => {
        if (data?.status) {
          toast.success(data?.message);
          Navigate(`/auth/forgot-password-otp/${data?.validateString}/${data.password}`)
        }
        else {
          toast.error(data?.message)
        }
      };
    }
  
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
           
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small> New password</small>
              </div>
              <Form role="form" onSubmit={handleSubmit(onSubmit)}>
              <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    {/* <InputGroupAddon addonType="prepend"> */}
                      <InputGroupText>
                      <i class="fa fa-lock" aria-hidden="true"></i>
                      </InputGroupText>
                    {/* </InputGroupAddon> */}
                    <input
                      placeholder="New Password"
                      type="password"
                      {...register("oldPassword", { required: true })}
                    />
  
                  </InputGroup>
                  {errors.oldPassword && <span className="text-danger d-flex"> This field is required</span>}
                </FormGroup>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    {/* <InputGroupAddon addonType="prepend"> */}
                      <InputGroupText>
                      <i class="fa fa-lock" aria-hidden="true"></i>
                      </InputGroupText>
                    {/* </InputGroupAddon> */}
                    <input
                      placeholder="Confirm Password"
                      type="password"
                      {...register("newPassword", { required: true })}
                    />
  
                  </InputGroup>
                  {errors.newPassword && <span className="text-danger d-flex"> This field is required</span>}
                </FormGroup>
             
                <div className="text-center">
                  <Button className="my-4" color="primary" type="submit"
                 
                  >
                    Sign in
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
         
        </Col>
      </>
    );
  };
  
  export default NewPassword;
  