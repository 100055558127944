import React, { useEffect,useState } from "react";
// reactstrap components
import {
 
  Button,
  Row,
  Col,
  Card,
  CardHeader
} from "reactstrap";
import { useNavigate} from 'react-router-dom';
import { get } from "../../utils/apiManager";

import { useLocation } from 'react-router-dom';



const ViewOne = () =>  {

    const location = useLocation()
    const Navigate = useNavigate()
    const [faq,setFaq] = useState({})

    useEffect(()=>{
        if(location?.search){
           
            getOneHomePage(location?.search?.split("?")[1])
        }
    },[])


    const getOneHomePage = (id) => {
        get(`/faq/${id}`).then((res) => {
           setFaq(res?.datas)
            
          }).catch((error) => {
            console.log(error, "erre");
          })
    }


    return (
      <>
            {/* <Header /> */}
           
         <Row className="mt-7">
          <Col className="mb-5 mb-xl-0 ml-5" xl="11" >
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0" style={{textAlign:"left"}}>View Faq</h3>
                    <Button  className='bi-trash justify-content-right' style={{position
                :"absolute",right:0,marginRight:"40px"}} onClick={() => Navigate(`/admin/faq`)} color="primary">Back</Button>
                  </div>
                 
                </Row>
              </CardHeader>
                <div style={{textAlign:"left",marginLeft:"30px"}}>
                    <p>Question : {faq?.question}</p><br />
                     <p>Answer : {faq?.answer}</p><br />
                    

                     <p>Status :{faq?.isActive ? <Button color="success" type="submit" className="ml-2">
                                          Active
                             </Button> : <Button color="danger" type="submit" className="ml-2">
                                          Deactive
                             </Button>}</p><br />

                </div>
            </Card>
          </Col>
         
         
        </Row>
      </>
    );
  
}

export default ViewOne