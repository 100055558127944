
import { useState, useEffect } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import * as Chart from 'chart.js';
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
// import {
//   chartOptions,
//   parseOptions,
//   chartExample1,
//   chartExample2,
// } from "../variables/charts.js";

import Header from "../components/Headers/Header.js";
import { get } from "../utils/apiManager.js";
import { Navigate, useNavigate } from "react-router-dom";
import BarChart from "../components/Chart/Barchart.js";

const Index = (props) => {
  const [activeNav, setActiveNav] = useState(1);
  const [chartExample1Data, setChartExample1Data] = useState("data1");
  const [totalUsers, setTotalUsers] = useState(0)
  const [companies, setCompanies] = useState([])
  const [companiesGraphData, setCompaniesGraphData] = useState([])
  const [activeUsers, setActiveUsers] = useState(0)


  const Navigate = useNavigate()

  if (window.Chart) {
    // parseOptions(Chart, chartOptions());
  }

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data("data" + index);
  };


  useEffect(() => {
    getAllUsers()
  }, [])

  const getAllUsers = () => {
    get("/company/latest-users").then((res) => {

      setTotalUsers(res?.totalUsers)
      setActiveUsers(res?.activeUsers)
      setCompanies(res?.datas)
      setCompaniesGraphData(res?.fullMonthsData)
    }).catch((error) => {
      console.log(error, "erre");
    })
  }

  return (
    <>
      <Header totalUsers={totalUsers} activeUsers={activeUsers} />
      {/* Page content */}
      <Container className="mt--7" fluid>

        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0 text-left">Latest Companies</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={(e) => Navigate("/admin/user")}
                    // size="sm"
                    >
                      See all
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Company name</th>
                    <th scope="col">Company type</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Apt Suite</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    companies?.length > 0 ?
                      companies.map((item, index) => {
                        return (
                          <tr key={item?._id}>
                            <th scope="row">{item?.firstName ? item?.firstName + " " + item?.lastName : "-"}</th>
                            <td>{item?.companyName || "-"}</td>
                            <td>{item?.companyType || "-"} </td>
                            <td>{item?.email || "-"}</td>
                            <td>{item?.phone || "-"}</td>
                            <td>{item?.addressAptSuite || "-"}</td>
                            <td>{item?.isActive ? <Button color="success" type="submit" className="">
                              Active
                            </Button> : <Button color="danger" type="submit" className="">
                              Deactive
                            </Button>}</td>


                          </tr>
                        )
                      })
                      : <tr> <div className="pagination-centered">No Record Found</div></tr>
                  }
                </tbody>
              </Table>

            </Card>


          </Col>

          <BarChart companiesGraphData={companiesGraphData} />


          {/* <Col xl="4">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Social traffic</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      See all
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Referral</th>
                    <th scope="col">Visitors</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Facebook</th>
                    <td>1,480</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">60%</span>
                        <div>
                          <Progress
                            max="100"
                            value="60"
                            barClassName="bg-gradient-danger"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Facebook</th>
                    <td>5,480</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">70%</span>
                        <div>
                          <Progress
                            max="100"
                            value="70"
                            barClassName="bg-gradient-success"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Google</th>
                    <td>4,807</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">80%</span>
                        <div>
                          <Progress max="100" value="80" />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Instagram</th>
                    <td>3,678</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">75%</span>
                        <div>
                          <Progress
                            max="100"
                            value="75"
                            barClassName="bg-gradient-info"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">twitter</th>
                    <td>2,645</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">30%</span>
                        <div>
                          <Progress
                            max="100"
                            value="30"
                            barClassName="bg-gradient-warning"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </Col> */}
        </Row>
      </Container>

    </>
  );
};

export default Index;
