import React, { useRef, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);



const BarChart = ({ companiesGraphData }) => {
    const chartRef = useRef(null);

    const labels = companiesGraphData?.map((val) => val.month)

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Compaies',
                data: companiesGraphData?.map((val) => val.count), // Example static data for Dataset 1
                backgroundColor: 'rgba(53, 162, 235, 0.5)', // Example color for Dataset 1
            },

        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Companies Graph',
            },
        },
    };

    useEffect(() => {
        if (chartRef && chartRef.current && chartRef.current.chartInstance) {
            chartRef.current.chartInstance.destroy();
        }
    }, []);

    return (

        <div className="col-6 ml-3 mt-3" style={{ background: "white" }} >
            <div className="chart-container" style={{ position: 'relative', height: '400px', width: '100%' }}>
                <Bar ref={chartRef} data={data} options={options} />
            </div>
        </div>

    );
};

export default BarChart;
