import { createSlice } from '@reduxjs/toolkit'

export const counterSlice = createSlice({
  name: 'auth',
  initialState: {
    token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
    userId: null,
    email: null,
    role: null,
    sidebarShow: true,
    isLogin: false,
    name: null,
    profileImage: null,
  },
  reducers: {

    LoginDetails: (state, action) => {
      console.log(action.payload.token)
      localStorage.setItem("token", action.payload.token)
      state.token = action.payload.token
      state.userId = action.payload.userId
      state.email = action.payload.email
      state.role = action.payload.role
      state.sidebarShow = true
      state.isLogin = action.payload.isLogin
      state.name = action.payload.name
      state.profileImage = action.payload.profileImage

    },
    LogoutDetails: (state, action) => {
      localStorage.setItem("token", null)
      state.token = null
      state.userId = action.payload.userId
      state.email = action.payload.email
      state.role = action.payload.role
    },
    toggleVisible: (state, action) => {
      state.sidebarShow = true
    },
    toggleInvisible: (state) => {
      state.sidebarShow = false
    },

  },
})

export const { LoginDetails, LogoutDetails, toggleVisible, toggleInvisible } = counterSlice.actions

export default counterSlice.reducer