import React from "react";


// reactstrap components
import {
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Button,
  Card,
  CardHeader
} from "reactstrap";
import Header from "../../components/Headers/Header";
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { post } from "../../utils/apiManager";
import { toast } from "react-toastify";
import { baseURL } from "../../config/config";

const Add = () => {

  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const Navigate = useNavigate()

  const onSubmit = (data) => {

    const Url = `${baseURL}/faq/create`
    post(Url, data).then((data) => {
      if (data?.status) {
        // toast.success(data?.message);
        Navigate("/admin/faq")

      }
      else {

        toast.error(data?.message)
      }
    }).catch(err => {
      toast.error(err.response.data.error)

    })

  }

  return (
    <>
      {/* <Header /> */}
      {/* <Link to="/admin/user/index">
            <Button color="primary" type="button" className="mt-3" style={{position:"absolute",left:0}}>
           Back
        </Button>
        </Link> */}
      <Row className="mt-7">
        <Col className="mb-5 mb-xl-0 ml-5" xl="11">
          <Card className="shadow">
            <CardHeader className="border-0">
              <div className="d-flex">
                <h2 style={{ textAlign: "left", marginTop: "10px" }}>Faq Detail Add</h2>
                <Button
                  className='bi-trash justify-content-right'
                  style={{ position: "absolute", right: 0, marginRight: "40px" }}
                  onClick={() => Navigate(`/admin/faq`)}
                  color="primary"
                >
                  Back
                </Button>
              </div>
              <Form style={{ marginTop: "40px" }} onSubmit={handleSubmit(onSubmit)}>



                <Row>
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Question <span className="text-danger">*</span></label>
                      <InputGroup className="input-group-alternative">
                        <textarea
                          id="question"
                          name="question"
                          placeholder="Enter question"
                          {...register("question", { required: "This field is required" })}
                          className="mt-1 block w-full px-3 py-2 form-control border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm textarea-height"

                        />
                      </InputGroup>
                      {errors.question && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Answer <span className="text-danger">*</span></label>
                      <InputGroup className="input-group-alternative">
                        <textarea
                          id="answer"
                          name="answer"
                          placeholder="Enter answer"
                          {...register("answer", { required: "This field is required" })}
                          className="mt-1 block w-full px-3 py-2 form-control border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm textarea-height"

                        />
                      </InputGroup>
                      {errors.answer && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  </Col>
                </Row>
                <Button color="primary" type="submit" className="d-flex">
                  Submit
                </Button>
              </Form>
            </CardHeader>
          </Card>
        </Col>
      </Row>
    </>
  );

}

export default Add;