import React, { useEffect, useState } from "react";
// reactstrap components
import {
    Table,
    Button,
    Row,
    Col,
    Card,
    CardHeader,
    InputGroup,
    FormGroup,
    Form
} from "reactstrap";
import Header from "../../components/Headers/Header";
import { Link, useNavigate } from 'react-router-dom';
import { get, post } from "../../utils/apiManager";
import { baseURL } from "../../config/config";
import { toast } from "react-toastify";
import Swal from 'sweetalert2'
// import PaginatedItems from "../../components/ReactPagination";
import Pagination from 'react-js-pagination'
import { useForm } from "react-hook-form";
import Modal from 'react-bootstrap/Modal';
import moment from "moment";
import SuccessModal from "../../components/SuccessModal";


const Index = () => {
    const { register, handleSubmit, formState: { errors }, getValues, setValue } = useForm();
    const { register: registerNewsletter, handleSubmit: handleSubmitNewsletter, formState: { errors: ErrorsNewsletter }, reset } = useForm();


    const [defaultTemplate, setDefaultTemplate] = useState([])
    const [checkBoxData, setCheckBoxData] = useState([])
    const [email, setEmail] = useState("")
    const Navigate = useNavigate()
    const [showModel, setShowModel] = useState(false)
    const [count, setCount] = useState()
    const [checkedValue, setCheckedValue] = useState(false)
    const [pageData, setPageData] = useState({
        page: 1,
        perPage: 5000,
        searchItem: "",
    });
    const [modalData, setModalData] =  useState({
        message: "",
        show: false
      });

    useEffect(() => {
        getAllDefaultTemplate()
    }, [pageData])

    const getAllDefaultTemplate = () => {
        get(`/default-template?page=${pageData?.page || 1}&perPage=${pageData?.perPage || 10
            }&searchItem=${getValues("companyName")}`).then((res) => {
                setDefaultTemplate(res?.data)
                setCount(res?.pages)
            }).catch((error) => {
                console.log(error, "erre");
            })
    }





    const onSubmit = (data) => {
        getAllDefaultTemplate()
    }

    const clearSearch = () => {
        setValue("companyName", "")
        getAllDefaultTemplate()
    }

    const changePage = (index) => {
        setPageData({
            ...pageData,
            page: index,
        });
    };


    const onSubmitNewsletter = (data) => {
        if (checkBoxData.length > 0) {

            const Url = `${baseURL}/news-letter/reply`

            post(Url, { email: checkBoxData, message: data.message, subject: data.subject }).then((data) => {
                if (data?.status) {
                    // toast.success(data?.message);
                    setModalData({ 
                        message: data?.message,
                        show: true
                      })
                    setEmail("")
                    setValue("message", "")
                    setCheckBoxData([])
                    reset()
                    setShowModel(false)

                }
                else {

                    toast.error(data?.message)
                }
            }).catch(err => {
                toast.error(err.response.data.error)

            })
        }
    }

    const changeStatus = (id, status) => {
        const Url = `${baseURL}/default-template/status`
        let data = { id, status: !status }
        post(Url, data).then((data) => {
            if (data?.status) {
                // toast.success(data?.message);
                setModalData({ 
                    message: data?.message,
                    show: true
                  })
                getAllDefaultTemplate()
            }
            else {
                toast.error(data?.message)
            }
        }).catch(err => {
            toast.error(err.response.data.error)
        })
    }

    const deleteHandler = (id) => {
        const Url = `${baseURL}/default-template/delete`
        let data = { id }
        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t delete this template',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                post(Url, data).then((data) => {
                    if (data?.status) {
                        // toast.success(data?.message);  
                        setModalData({ 
                            message: data?.message,
                            show: true
                          })
                        getAllDefaultTemplate()
                    }
                    else {
                        toast.error(data?.message)
                    }
                }).catch(err => {
                    toast.error(err.response.data.error)
                })
            }
        });
    }

    const checkBoxHandler = (email) => {
        let findData = checkBoxData?.find(item => item == email)
        if (findData) {
            let filterData = checkBoxData?.filter((item) => item != email)
            setCheckBoxData(filterData)
        } else {
            setCheckBoxData((prev) => [...prev, email])
        }
    }

    const allCheck = (val) => {
        if (val) {
            let newData = []
            defaultTemplate?.map((item) => {
                newData.push(item.email)
            })
            setCheckBoxData(newData)

        } else {
            setCheckBoxData([])
        }
        setCheckedValue(!checkedValue)

    }

    useEffect(() => {
        if (defaultTemplate.length > 0) {
            if (defaultTemplate.length == checkBoxData.length) {
                setCheckedValue(true)
            } else {
                setCheckedValue(false)

            }
        }

    }, [checkBoxData])


    return (
        <>
            <Row className="mt-7" >
                <Col className="mb-5 mb-xl-0 ml-5" xl="11" >
                    <Card className="shadow">
                        <CardHeader className="border-0">
                            <Row className="align-items-center " >
                                <div className="col">
                                    <h3 className="mb-0" style={{ textAlign: "left" }}>Template Management</h3>
                                </div>
                                {/* {
                                    checkBoxData?.length > 0 &&
                                    <>

                                        <Button
                                            color="primary"
                                            onClick={() => setShowModel(true)}
                                        >
                                            All Reply
                                        </Button>
                                    </>
                                } */}
                                <Link to="/admin/default-template/add">

                                    <Button
                                        color="primary"
                                        href="#pablo"
                                    >
                                        Add Template
                                    </Button>
                                </Link>
                            </Row>
                        </CardHeader>

                        <Row className="d-flex" style={{ justifyContent: "right" }}>
                            <div >
                                <Form onSubmit={handleSubmit(onSubmit)} >
                                    <Row>
                                        <Col md="6" >
                                            <FormGroup className="mb-3">
                                                <InputGroup className="input-group-alternative">
                                                    <input
                                                        placeholder="Searching......."
                                                        type="text"
                                                        autoComplete="new-email"
                                                        {...register("companyName")}
                                                        style={{ border: "1px solid" }}
                                                        className="form-control"
                                                    />
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>

                                        <Button color="success" type="submit" className="d-flex" >
                                            Search
                                        </Button>
                                        <Button color="danger" className="d-flex" onClick={clearSearch}
                                            style={{ marginRight: "40px" }}
                                        >
                                            Clear
                                        </Button>
                                    </Row>
                                </Form>
                            </div>
                        </Row>

                        <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                                <tr>
                                    {/* <th scope="col" width="2%">Checkbox</th> */}
                                    {/* <th>
                                        <input className="custom-control-input" style={{ opacity: 1, position: "revert" }}
                                            checked={checkedValue}
                                            value={checkedValue} type="checkbox" onClick={(e) => allCheck(!checkedValue)} />
                                    </th> */}
                                    <th scope="col" width="30%">Name</th>
                                    <th scope="col" width="30%">Create Date</th>
                                    <th scope="col" width="20%">Status</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    defaultTemplate?.length > 0 ?
                                        defaultTemplate.map((item, index) => {
                                            return (
                                                <tr key={item?._id}>
                                                    {/* <td>
                                                        <input className="custom-control-input" style={{ opacity: 1, position: "revert" }} id={item?.email} type="checkbox" value={item.email} checked={checkBoxData?.includes(item.email)} onChange={() => { checkBoxHandler(item.email) }} />
                                                    </td> */}
                                                    <td >
                                                        <Link to={`/admin/template/manage-field?${item._id}`}>
                                                            {item?.templateName}
                                                        </Link>
                                                    </td>
                                                    <td >{moment(item?.createdAt).format("MMMM DD,YYYY hh:mm A")}</td>
                                                    <td>{item?.isActive ? <Button color="success" type="submit" className="">
                                                        Active
                                                    </Button> : <Button color="danger" type="submit" className="">
                                                        Deactive
                                                    </Button>}</td>
                                                    <td>
                                                        {
                                                            item?.isActive ?
                                                                <i style={{ color: "red", width: "30px", fontSize: "20px" }} onClick={() => changeStatus(item._id, item?.isActive)} class="fa fa-times" aria-hidden="true"></i> :
                                                                <i style={{ color: "green", width: "30px", fontSize: "20px" }} onClick={() => changeStatus(item._id, item?.isActive)} class="fa fa-check" aria-hidden="true"></i>
                                                        }
                                                        <i style={{ color: "black", width: "30px", fontSize: "20px" }} onClick={() => Navigate(`/admin/default-template/edit?${item._id}`)} class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                                        <i onClick={() => deleteHandler(item._id)} class="fa fa-trash" aria-hidden="true" style={{ color: "red", width: "30px", fontSize: "20px" }}></i>



                                                    </td>
                                                </tr>
                                            )
                                        })
                                        : "No Record Found"
                                }
                            </tbody>
                        </Table>
                        {/* {defaultTemplate?.length ?
                            <div className="pagination-centered">
                                <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={pageData?.page}
                                    itemsCountPerPage={pageData?.perPage}
                                    totalItemsCount={count}
                                    pageRangeDisplayed={5}
                                    onChange={changePage}
                                />
                            </div> : ''} */}
                    </Card >
                </Col >
            </Row >
            <Modal
                show={showModel}
                onHide={() => { setShowModel(false); setEmail("") }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Message Reply
                    </Modal.Title>
                </Modal.Header>
                <form onSubmit={handleSubmitNewsletter(onSubmitNewsletter)}>
                    <Modal.Body>
                        <Col md="12">
                            <FormGroup className="mb-3">

                                <label className="d-flex">Subject  </label>
                                <InputGroup className="input-group-alternative">
                                    <input
                                        placeholder="Please Enter Subject"
                                        type="text"
                                        {...registerNewsletter("subject", { required: true })}
                                        className="form-control border"
                                    />

                                </InputGroup>
                                {ErrorsNewsletter.subject && <span className="text-danger d-flex"> This field is required</span>}
                            </FormGroup>
                            <FormGroup className="mb-3">

                                <label className="d-flex">Message  </label>
                                <InputGroup className="input-group-alternative">
                                    <textarea
                                        placeholder="Please Enter Message"
                                        type="text"
                                        {...registerNewsletter("message", { required: true })}
                                        className="form-control border textarea-height"


                                    />

                                </InputGroup>
                                {ErrorsNewsletter.message && <span className="text-danger d-flex"> This field is required</span>}
                            </FormGroup>
                        </Col>
                    </Modal.Body>

                    <Modal.Footer>

                        <Button onClick={() => { setShowModel(false); setEmail("") }}>Close</Button>
                        <Button type="submit">Submit</Button>
                    </Modal.Footer>
                </form>
            </Modal>
            <SuccessModal message={modalData?.message} showModal={modalData?.show} setShowModal={setModalData} />

        </>
    );
}

export default Index


