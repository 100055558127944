import React, { useEffect, useState } from "react";


// reactstrap components
import {
  FormGroup,
  Form,
  InputGroup,
  Row,
  Col,
  Button,
  Card,
  CardHeader
} from "reactstrap";
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { post, get } from "../../utils/apiManager";
import { toast } from "react-toastify";
import { baseURL } from "../../config/config";

const Edit = () => {
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const Navigate = useNavigate()
  const location = useLocation()
  const [showImageUrl, setShowImageUrl] = useState("")
  const [showImage, setShowImage] = useState(false)
  const [showImageCheck, setShowImageCheck] = useState(false)
  const [showIconUrl, setShowIconUrl] = useState("")
  const [showIcon, setShowIcon] = useState(false)
  const [showIconCheck, setShowIconCheck] = useState(false)


  useEffect(() => {
    if (location?.search) {
      getOneCategory(location?.search?.split("?")[1])
    }
  }, [])


  const getOneCategory = (id) => {
    get(`/category/${id}`).then((res) => {
      if (res?.datas) {
        setValue("name", res?.datas?.name)

        setValue("image", res?.datas?.image)
        setValue("icon", res?.datas?.icon)
        setValue("description", res?.datas?.description)

        setShowImageUrl(res?.datas?.image)
        setShowIconUrl(res?.datas?.icon)

        if (res?.datas?.image) {
          setShowImage(true)
        }

        if (res?.datas?.icon) {
          setShowIcon(true)
        }

      }

    }).catch((error) => {
      console.log(error, "erre");
    })
  }


  const onSubmit = (data) => {

    const Url = `${baseURL}/category/edit`


    let formData = new FormData();
    formData.append("name", data?.name)
    // if (showImageCheck) {
    //   formData.append("image", data?.image?.[0])
    // }
    if (showIconCheck) {
      formData.append("icon", data?.icon?.[0])
    }
    formData.append("isShow", showImageCheck)
    formData.append("isShowIcon", showIconCheck)
    formData.append("description", data?.description)
    formData.append("id", location?.search?.split("?")[1])

    post(Url, formData, 1).then((data) => {
      if (data?.status) {
        // toast.success(data?.message);
        Navigate("/admin/category")
      }
      else {
        toast.error(data?.message)
      }
    }).catch(err => {
      toast.error(err.response.data.error)

    })

  }

  const imageUrlChange = (e) => {

    const file = e?.target?.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result.split(',')[1]; // Get Base64 part only
        setShowImageUrl(base64String)
      };

      reader.readAsDataURL(file);
    }
  }

  const iconUrlChange = (e) => {
    const file = e?.target?.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result.split(',')[1]; // Get Base64 part only
        setShowIconUrl(base64String)
      };

      reader.readAsDataURL(file);
    }
  }



  return (
    <>

      <Row className="mt-7" >
        <Col className="mb-5 mb-xl-0 ml-5" xl="11" >
          <Card className="shadow">
            <CardHeader className="border-0">
              <div className="d-flex">
                <h2 style={{ textAlign: "left", marginTop: "10px" }}>Category Edit</h2>

                <Button className='bi-trash justify-content-right' style={{
                  position
                    : "absolute", right: 0, marginRight: "40px"
                }} onClick={() => Navigate(`/admin/category`)} color="primary">Back</Button>
              </div>
              <Form className="" style={{ marginTop: "40px" }} onSubmit={handleSubmit(onSubmit)}>
                <Row>



                  <Col md="4">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Name <span className="text-danger">*</span></label>

                      <InputGroup className="input-group-alternative">


                        <input
                          placeholder="Name"
                          type="text"
                          {...register("name", { required: true })}
                          className="form-control border"
                        />

                      </InputGroup>
                      {errors.name && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  </Col>


                  <Col md="4">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Icon  </label>

                      <InputGroup className="input-group-alternative">

                        <input
                          placeholder="Please Select Icon"
                          type="file"
                          {...register("icon", { required: { showIcon } })}
                          className="form-control border"
                          onChange={(e) => { setShowIconCheck(true); iconUrlChange(e) }}
                        />

                      </InputGroup>
                      {showIcon && <img src={showIconCheck ? `data:image/png;base64,${showIconUrl}` : baseURL + "/" + showIconUrl} width="100px" style={{ display: "flex", marginTop: "10px" }} />}
                      {errors.showIcon && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  </Col>

                  <Col md="4">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Description  </label>

                      <InputGroup className="input-group-alternative">

                        <textarea
                          placeholder="Enter Description"
                          type="text"
                          {...register("description", { required: false })}
                          className="form-control border"
                        />

                      </InputGroup>
                      {errors.description && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  </Col>



                  {/* <Col md="4">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Image</label>

                      <InputGroup className="input-group-alternative">

                        <input
                          placeholder="Please Select Image"
                          type="file"
                          {...register("image", { required: { showImage } })}
                          className="form-control border"
                          onChange={(e) => { setShowImageCheck(true); imageUrlChange(e) }}
                        />

                      </InputGroup>
                      {showImage && <img src={showImageCheck ? `data:image/png;base64,${showImageUrl}` : baseURL + "/" + showImageUrl} width="100px" style={{ display: "flex", marginTop: "10px" }} />}
                      {errors.image && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  </Col> */}

                </Row>

                <Button color="primary" type="submit" className="d-flex" style={{ marginTop: "10px" }} >
                  Update
                </Button>

              </Form>
            </CardHeader>
          </Card>
        </Col>
      </Row>
    </>
  );

}

export default Edit;