import React, { useEffect, useState } from "react";


// reactstrap components
import {
    FormGroup,
    Form,
    InputGroup,
    Row,
    Col,
    Button,
    Card,
    CardHeader
} from "reactstrap";
import Header from "../../components/Headers/Header";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { post, get } from "../../utils/apiManager";
import { toast } from "react-toastify";
import { baseURL } from "../../config/config";
import CKEditor from 'ckeditor4-react';
import Editor from "../../components/Editor";

const Add = () => {
    const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm();
    const Navigate = useNavigate()
    const location = useLocation()
    const [editorData, setEditorData] = useState()
    const isModified = watch("isModified")

    useEffect(() => {
        if (isModified == "true" || isModified) {
            setValue("isCompany", true)
        }
    }, [isModified])



    const onSubmit = (data) => {

        const Url = `${baseURL}/email-template`
        let newData = { ...data, description: editorData }
        post(Url, newData).then((data) => {
            if (data?.status) {
                // toast.success(data?.message);
                Navigate("/admin/email")
            }
            else {

                toast.error(data?.message)
            }
        }).catch(err => {
            toast.error(err.response.data.error)

        })

    }


    return (
        <>
            {/* <Header /> */}
            {/* <Link to="/admin/user/index">
            <Button color="primary" type="button" className="mt-3" style={{position:"absolute",left:0}}>
           Back
        </Button>
        </Link> */}
            <Row className="mt-7" >
                <Col className="mb-5 mb-xl-0 ml-5" xl="11" >
                    <Card className="shadow">
                        <CardHeader className="border-0">
                            <div className="d-flex">
                                <h2 style={{ textAlign: "left", marginTop: "10px" }}>Email Template Add</h2>

                                <Button className='bi-trash justify-content-right' style={{
                                    position
                                        : "absolute", right: 0, marginRight: "40px"
                                }} onClick={() => Navigate(`/admin/email`)} color="primary">Back</Button>
                            </div>
                            <Form className="" style={{ marginTop: "40px" }} onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Col md="6">
                                        <FormGroup className="mb-3">
                                            <label className="d-flex">Title <span className="text-danger">*</span></label>

                                            <InputGroup className="input-group-alternative">
                                                {/* <InputGroupAddon addonType="prepend"> */}
                                                {/* </InputGroupAddon> */}

                                                <input
                                                    placeholder="Title"
                                                    type="text"
                                                    autoComplete="new-email"
                                                    {...register("title", { required: true })}
                                                    className="form-control border"
                                                />

                                            </InputGroup>
                                            {errors.company_name && <span className="text-danger d-flex"> This field is required</span>}
                                        </FormGroup>
                                    </Col>

                                    <Col md="6">
                                        <FormGroup className="mb-3">
                                            <label className="d-flex">Subject <span className="text-danger">*</span></label>

                                            <InputGroup className="input-group-alternative">
                                                {/* <InputGroupAddon addonType="prepend"> */}
                                                {/* </InputGroupAddon> */}
                                                <input
                                                    placeholder="Subject"
                                                    type="text"
                                                    {...register("subject", { required: true })}
                                                    className="form-control border"
                                                />

                                            </InputGroup>
                                            {errors.subject && <span className="text-danger d-flex"> This field is required</span>}
                                        </FormGroup>
                                    </Col>
                                    <Col md="12">
                                        <Editor editorData={editorData} setEditorData={setEditorData} />
                                    </Col>
                                    <Col md="4 mt-3">
                                        <div className="custom-control custom-control-alternative custom-checkbox mb-3 text-left">
                                            <input class="custom-control-input"
                                                type="checkbox"
                                                value={true}
                                                {...register(`isCompany`, { required: false })}
                                                id="company"
                                                disabled={isModified}
                                            />
                                            <label class="custom-control-label"
                                                for="company"
                                            >
                                                Company
                                            </label>
                                        </div>
                                        <div className="custom-control custom-control-alternative custom-checkbox mb-3 text-left">
                                            <input class="custom-control-input"
                                                type="checkbox"
                                                value={true}
                                                {...register(`isSelf`, { required: false })}
                                                id="self"
                                            />
                                            <label class="custom-control-label"
                                                for="self"
                                            >
                                                Self
                                            </label>
                                        </div>

                                        <div className="custom-control custom-control-alternative custom-checkbox mb-3 text-left">
                                            <input class="custom-control-input"
                                                type="checkbox"
                                                value={true}
                                                {...register(`isModified`, { required: false })}
                                                id="modified"
                                            />
                                            <label class="custom-control-label"
                                                for="modified"
                                            >
                                                Modified
                                            </label>
                                        </div>


                                    </Col>
                                </Row>



                                <Button color="primary" type="submit" className="d-flex" style={{ marginTop: "10px" }} >
                                    Submit
                                </Button>

                            </Form>
                        </CardHeader>
                    </Card>
                </Col>
            </Row>
        </>
    );

}

export default Add;