import React, { useEffect, useState } from "react";
// reactstrap components
import {
    Badge,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Progress,
    Table,
    UncontrolledTooltip,
    Container,
    Button,
    Row,
    Col,
    Card,
    CardHeader
} from "reactstrap";
import Header from "../../components/Headers/Header";
import { Link, useNavigate } from 'react-router-dom';
import { get, post } from "../../utils/apiManager";
import { baseURL } from "../../config/config";
import { toast } from "react-toastify";
import { useLocation } from 'react-router-dom';



const ViewOne = () => {

    const location = useLocation()
    const [contactData, setContactData] = useState({})
    const Navigate = useNavigate()

    useEffect(() => {
        if (location?.search) {
            getContactData(location?.search?.split("?")[1])
        }
    }, [])


    const getContactData = (id) => {
        get(`/news-letter/contact-us-one/${id}`).then((res) => {
            setContactData(res?.datas)

        }).catch((error) => {
            console.log(error, "erre");
        })
    }






    return (
        <>
            {/* <Header /> */}

            <Row className="mt-7">
                <Col className="mb-5 mb-xl-0 ml-5" xl="11" >
                    <Card className="shadow">
                        <CardHeader className="border-0">
                            <Row className="align-items-center">
                                <div className="col">
                                    <h3 className="mb-0" style={{ textAlign: "left" }}>View Contact Us</h3>
                                    <Button className='bi-trash justify-content-right' style={{
                                        position
                                            : "absolute", right: 0, marginRight: "40px"
                                    }} onClick={() => Navigate(`/admin/contact-us`)} color="primary">Back</Button>
                                </div>

                            </Row>
                        </CardHeader>
                        <div style={{ textAlign: "left", marginLeft: "30px" }}>
                            <p>Name : {contactData?.name}</p><br />
                            <p>Email : {contactData?.email}</p><br />
                            <p>Subject : {contactData?.subject}</p><br />
                            <p>Phone Number : {contactData?.phoneNumber}</p><br />
                            <p>Message : {contactData?.message}</p><br />

                            <p>Status :{contactData?.isActive ? <Button color="success" type="submit" className="ml-2">
                                Active
                            </Button> : <Button color="danger" type="submit" className="ml-2">
                                Deactive
                            </Button>}</p><br />


                        </div>
                    </Card>
                </Col>


            </Row>
        </>
    );

}

export default ViewOne