/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import { useDispatch, useSelector } from "react-redux";
import { Button, Container, Row, Col } from "reactstrap";
import { post } from "../../utils/apiManager";
import { toast } from "react-toastify";
import { baseURL } from "../../config/config";
import { LoginDetails } from "../../reduxToolkit/slice/auth";

const UserHeader = ({ isShowEditProfile, setIsShowEditProfile, profileName, sendProfileImage, isProfileUpdate, isChangePassword, setIsChangePassword, oldPassword, newPassword, confirmPassword, setOldPassword, setNewPassword, setConfirmPassword }) => {
  const { name, userId } = useSelector(state => state.auth);
  return (
    <>
      <div
        className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        style={{
          minHeight: "600px",
          backgroundImage:
            "url(" + require("../../assets/img/theme/profile-cover.jpg") + ")",
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        {/* Mask */}
        <span className="mask bg-gradient-default opacity-8" />
        {/* Header container */}
        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="7" md="10" >
              <h1 className="display-2 text-white">{`Hello ${name}`}</h1>
              <p className="text-white mt-0 mb-5">
                This is your profile page. You can see the progress you've made
                with your work and manage your projects or assigned tasks
              </p>
              <div className="d-flex">
                <Button
                  // color="info"
                  // style={{"}}
                  onClick={(e) => { setIsShowEditProfile(false) }}
                  style={{ backgroundColor: "#cc333c" }}
                  disabled={!isShowEditProfile}
                  className="text-white"
                >
                  Edit Profile
                </Button>
                <Button
                  color="info"
                  onClick={(e) => { setIsChangePassword(false) }}
                  style={{ backgroundColor: "#cc333c" }}
                  disabled={!isChangePassword}
                >
                  Change Password

                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default UserHeader;
