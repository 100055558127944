import React from "react";
import { Modal } from "react-bootstrap";
 
export default function SuccessModal({ showModal, setShowModal, message }) {
    return (
        <Modal
            show={showModal}
            onHide={() => {
                setShowModal({message: "", show : false}); 
            }}  
            keyboard={false}
            centered  
        >
            <Modal.Body style={{ position: "relative" }}>
                {/* Close button on top right */}
                <button
                    type="button"
                    aria-label="Close"
                    onClick={() => {
                        setShowModal({message: "", show : false}); 
                    
                    }}
                    style={{
                        position: "absolute",
                        right: "10px",
                        top: "-2px",
                        background: "none",
                        border: "none",
                        fontSize: "24px",
                        cursor: "pointer",
                    }}
                >
                    &times;

                </button>

                {/* Modal Content */}
                <h2 style={{ textAlign: "center" }}>{message}</h2>
            </Modal.Body>

            <Modal.Footer style={{ justifyContent: "center" }}>
                <button
                    style={{ textAlign: "center" }}
                    className="btn btn-primary"
                    onClick={() => {
                        setShowModal({message: "", show : false}); 
                    
                    }}  
                >
                    Ok
                </button>
            </Modal.Footer>
        </Modal>
    )
} 