import React, { useEffect, useState } from "react";
import {

  Button,
  Row,
  Col,
  Card,
  CardHeader
} from "reactstrap";
import { useNavigate } from 'react-router-dom';
import { get } from "../../utils/apiManager";

import { useLocation } from 'react-router-dom';
import { baseURL } from "../../config/config";



const ViewOne = () => {

  const location = useLocation()
  const Navigate = useNavigate()
  const [category, setCategory] = useState({})

  useEffect(() => {
    if (location?.search) {
      getOneCategory(location?.search?.split("?")[1])
    }
  }, [])


  const getOneCategory = (id) => {
    get(`/category/${id}`).then((res) => {
      setCategory(res?.datas)

    }).catch((error) => {
      console.log(error, "erre");
    })
  }


  return (
    <>
      <Row className="mt-7">
        <Col className="mb-5 mb-xl-0 ml-5" xl="11" >
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <div className="col">
                  <h3 className="mb-0" style={{ textAlign: "left" }}>View Category</h3>
                  <Button className='bi-trash justify-content-right' style={{
                    position
                      : "absolute", right: 0, marginRight: "40px"
                  }} onClick={() => Navigate(`/admin/category`)} color="primary">Back</Button>
                </div>
              </Row>
            </CardHeader>
            <div style={{ textAlign: "left", marginLeft: "30px" }}>

              <p>Name : {category?.name}</p><br />
              <p>Description : {category?.description ? category?.description : "-"}</p><br />
              {/* <p>Icon : {category?.image ? <img src={baseURL + "/" + category?.icon} width="100px" /> : "-"}</p><br /> */}
              <p>Image : {category?.image ? <img src={baseURL + "/" + category?.image} width="100px" /> : "-"}</p><br />
              <p>Status :{category?.isActive ? <Button color="success" type="submit" className="ml-2">
                Active
              </Button> : <Button color="danger" type="submit" className="ml-2">
                Deactive
              </Button>}</p><br />
            </div>
          </Card>
        </Col>


      </Row>
    </>
  );

}

export default ViewOne