import React, { useEffect,useState } from "react";
// reactstrap components
import {
 
  Button,
  Row,
  Col,
  Card,
  CardHeader
} from "reactstrap";
import { useNavigate} from 'react-router-dom';
import { get } from "../../utils/apiManager";

import { useLocation } from 'react-router-dom';
import { baseURL } from "../../config/config";



const ViewOne = () =>  {

    const location = useLocation()
    const Navigate = useNavigate()
    const [blog,setBlog] = useState({})

    useEffect(()=>{
        if(location?.search){           
            getOneBlog(location?.search?.split("?")[1])
        }
    },[])


    const getOneBlog = (id) => {
        get(`/pricing/${id}`).then((res) => {
          setBlog(res?.datas)
            
          }).catch((error) => {
            console.log(error, "erre");
          })
    }



    


    return (
      <>           
         <Row className="mt-7">
          <Col className="mb-5 mb-xl-0 ml-5" xl="11" >
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0" style={{textAlign:"left"}}>View Plan</h3>
                    <Button  className='bi-trash justify-content-right' style={{position
                :"absolute",right:0,marginRight:"40px"}} onClick={() => Navigate(`/admin/pricing`)} color="primary">Back</Button>
                  </div>
                </Row>
              </CardHeader>
                <div style={{textAlign:"left",marginLeft:"30px"}}>
                {/* planName,
        isActive,
        upfrontFee,
        upfrontFeeAmount,
        description,
        monthlyFee,
        monthlyFeeAmount,
        salesPercentage,
        parSalesPercentage,
        salesPercentageAmount,
        parSalesPercentageAmount, */}
                     <p>Plan Name : {blog?.planName}</p><br />
                     <p>description : {blog?.description}</p><br />
                     <p>Upfront Fee : {blog?.upfrontFee ? "true" :"-"}</p><br />
                     <p>Plan Fee Amount : {blog?.upfrontFeeAmount}</p><br />
                     <p>Monthly Fee : {blog?.monthlyFee ? "true" :"-"}</p><br />
                     <p>Monthly Fee Amount : {blog?.monthlyFeeAmount}</p><br />
                     <p>Sales Percentage : {blog?.salesPercentage ? "true" :"-"}</p><br />
                     <p>Sales Percentage Amount : {blog?.salesPercentageAmount}</p><br />
                     <p>Par Sales Percentage : {blog?.parSalesPercentage ? "true" :"-"}</p><br />
                     <p>Par Sales Percentage  Amount: {blog?.parSalesPercentageAmount}</p><br />


                     {/* <p>Description :   {blog?.description != "undefined" ? <p dangerouslySetInnerHTML={{__html: blog?.description}}/>:"-"}</p><br/>
                     <p>Image : {blog?.image ?<img src={baseURL +"/" + blog?.image} width="100px" /> :"-" }</p><br /> */}
                     <p>Status :{blog?.isActive ? <Button color="success" type="submit" className="ml-2">
                                          Active
                             </Button> : <Button color="danger" type="submit" className="ml-2">
                                          Deactive
                             </Button>}</p><br />
                </div>
            </Card>
          </Col>
         
         
        </Row>
      </>
    );
  
}

export default ViewOne