import React, { useEffect, useState } from "react";
// reactstrap components
import {
    FormGroup,
    Form,
    InputGroup,
    Row,
    Col,
    Button,
    Card,
    CardHeader
} from "reactstrap";
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { get, post } from "../utils/apiManager";
import { toast } from "react-toastify";
import { baseURL } from "../config/config";



const Add = () => {
    const { register, handleSubmit, formState: { errors }, unregister, watch } = useForm();
    const Navigate = useNavigate()
    const location = useLocation()
    const id = location?.search?.split("?")[1]

    const onSubmit = (data) => {
        if (data.newPassword != data.confirmPassword) {
            toast.error("New password and confirm password did not match")
            return
        }
        const Url = `${baseURL}/company/change-password`
        data.id = id
        post(Url, data).then((data) => {
            if (data?.status) {  
                // toast.success(data?.message);
                Navigate("/admin/user")
            }  
            else {
                toast.error(data?.message)
            }
        }).catch(err => {
            toast.error(err.response.data.error)
        })
    }



    const validatePassword = (value) => {
        return (
            /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[A-Z]).{8,}$/.test(value) ||
            "Password must contain at least one number, one uppercase letter, one special character, and be at least 8 characters long"
        );
    };

    return (
        <>
            <Row className="mt-7 mx-0">
                <Col className="mb-5 mb-xl-0 ml-5" xl="11">
                    <Card className="shadow">
                        <CardHeader className="border-0">
                            <div className="d-flex">
                                <h2 style={{ textAlign: "left", marginTop: "10px" }}>Change Password</h2>
                                <Button
                                    className='bi-trash justify-content-right'
                                    style={{ position: "absolute", right: 0, marginRight: "40px" }}
                                    onClick={() => Navigate(`/admin/user`)}
                                    color="primary"
                                >
                                    Back
                                </Button>
                            </div>
                            <Form style={{ marginTop: "40px" }} onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Col md="4">
                                        <FormGroup className="mb-3">
                                            <label className="d-flex">New Password <span className="text-danger">*</span></label>
                                            <InputGroup className="input-group-alternative">
                                                <input
                                                    placeholder="New Password"
                                                    type="text"
                                                    {...register("newPassword", { required: "This field is required ", validate: validatePassword })}
                                                    className="border form-control"
                                                />
                                            </InputGroup>
                                            {errors.newPassword && <span className="text-danger d-flex"> {errors?.newPassword?.message}</span>}
                                        </FormGroup>
                                    </Col>

                                    <Col md="4">
                                        <FormGroup className="mb-3">
                                            <label className="d-flex">Confirm Password <span className="text-danger">*</span></label>
                                            <InputGroup className="input-group-alternative">
                                                <input
                                                    placeholder="Confirm Password"
                                                    type="text"
                                                    {...register("confirmPassword", { required: "This field is required " })}
                                                    className="border form-control"
                                                />
                                            </InputGroup>
                                            {errors.confirmPassword && <span className="text-danger d-flex"> {errors?.confirmPassword?.message}</span>}
                                        </FormGroup>
                                    </Col>
                                </Row>



                                <Button color="primary" type="submit" className="d-flex">
                                    Submit
                                </Button>
                            </Form>
                        </CardHeader>
                    </Card>
                </Col>
            </Row>
        </>
    );
}
export default Add;
