// export const baseURL = 'http://172.16.11.227:3007'
// export const ImageUrl = 'http://172.16.1.226:3007/images/'
// export const ImageUrl2 = 'http://172.16.1.226:3007/'


export const baseURL = 'https://e-auctionapi.devtechnosys.tech'
export const ImageUrl = 'https://e-auctionapi.devtechnosys.tech/images/'
export const ImageUrl2 = 'https://e-auctionapi.devtechnosys.tech/'

// NjQwMTdiOTEwMmRlZmY2ZTNkOTA3MWUwOjkxMTUxMg ==

export const google_api_key = 'AIzaSyBNPYbsmeUKRDH6WgLqnmyR2C60kgjkqoQ'