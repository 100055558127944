import React, { useEffect, useState } from "react";
// reactstrap components
import {
    Table,
    Button,
    Row,
    Col,
    Card,
    CardHeader,
    InputGroup,
    FormGroup,
    Form
} from "reactstrap";
import Header from "../../components/Headers/Header";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { get, post } from "../../utils/apiManager";
import { baseURL } from "../../config/config";
import { toast } from "react-toastify";
import Swal from 'sweetalert2'
// import PaginatedItems from "../../components/ReactPagination";
import Pagination from 'react-js-pagination'
import { useForm } from "react-hook-form";
import Modal from 'react-bootstrap/Modal';
import moment from "moment";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SuccessModal from "../../components/SuccessModal";


const Index = () => {
    const { register, handleSubmit, formState: { errors }, getValues, setValue } = useForm();
    const { register: registerManageField, handleSubmit: handleSubmitManageField, formState: { errors: ErrorsManageField }, reset, watch, setValue: setValueManageField } = useForm();

    const location = useLocation()
    let id = location?.search?.split("?")[1]
    const [manageField, setManageField] = useState([])
    const [checkBoxData, setCheckBoxData] = useState([])
    const [email, setEmail] = useState("")
    const Navigate = useNavigate()
    const [showModel, setShowModel] = useState(false)
    const [count, setCount] = useState()
    const [checkedValue, setCheckedValue] = useState(false)
    const [manageFieldData, setManageFieldData] = useState("")
    const [pageData, setPageData] = useState({
        page: 1,
        perPage: 5000,
        searchItem: "",
    });
    const [modalData, setModalData] = useState({
        message: "",
        show: false
    });
    useEffect(() => {
        getAllManageField()
    }, [pageData])


    useEffect(() => {
        if (manageFieldData != "") {
            setValueManageField("fieldLabel", manageFieldData?.fieldLabel)
            setValueManageField("fieldType", manageFieldData?.fieldType)
            setValueManageField("width", manageFieldData?.width)
            setValueManageField("Choices", manageFieldData?.Choices)
            setValueManageField("defaultValue", manageFieldData?.defaultValue)
            setValueManageField("currency", manageFieldData?.currency)
            setValueManageField("currenacyPosition", manageFieldData?.currenacyPosition)
            setValueManageField("defaultCheckedValue", manageFieldData?.defaultCheckedValue)
            setValueManageField("align", manageFieldData?.align)
            setValueManageField("defaultValueQuan", manageFieldData?.defaultValueQuan)




        }

    }, [manageFieldData])


    const getAllManageField = () => {
        get(`/default-manage-field/all/${id}?page=${pageData?.page || 1}&perPage=${pageData?.perPage || 10
            }&searchItem=${getValues("companyName")}`).then((res) => {
                setManageField(res?.data)
                setCount(res?.pages)
            }).catch((error) => {
                console.log(error, "erre");
            })
    }


    const onSubmit = (data) => {
        getAllManageField()
    }

    const clearSearch = () => {
        setValue("companyName", "")
        getAllManageField()
    }

    const changePage = (index) => {
        setPageData({
            ...pageData,
            page: index,
        });
    };


    const onSubmitManageField = (data) => {
        let Url;

        if (manageFieldData != "") {

            Url = `${baseURL}/default-manage-field/update`
            data.id = manageFieldData?._id
        } else {
            Url = `${baseURL}/default-manage-field/add`
            data.templateId = id
        }

        post(Url, data).then((data) => {
            if (data?.status) {
                // toast.success(data?.message);
                setModalData({
                    message: data?.message,
                    show: true
                })
                reset()
                setShowModel(false)
                getAllManageField()
            }
            else {

                toast.error(data?.message)
            }
        }).catch(err => {
            toast.error(err.response.data.error)

        })
    }

    const changeStatus = (id, status) => {
        const Url = `${baseURL}/default-manage-field/status`
        let data = { id, status: !status }
        post(Url, data).then((data) => {
            if (data?.status) {
                // toast.success(data?.message);
                setModalData({
                    message: data?.message,
                    show: true
                })
                getAllManageField()
            }
            else {
                toast.error(data?.message)
            }
        }).catch(err => {
            toast.error(err.response.data.error)
        })
    }

    const deleteHandler = (id) => {
        const Url = `${baseURL}/default-manage-field/delete`
        let data = { id }
        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t delete this manage field',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                post(Url, data).then((data) => {
                    if (data?.status) {
                        // toast.success(data?.message);
                        setModalData({
                            message: data?.message,
                            show: true
                        })
                        getAllManageField()
                    }
                    else {
                        toast.error(data?.message)
                    }
                }).catch(err => {
                    toast.error(err.response.data.error)
                })
            }
        });
    }

    const checkBoxHandler = (email) => {
        let findData = checkBoxData?.find(item => item == email)
        if (findData) {
            let filterData = checkBoxData?.filter((item) => item != email)
            setCheckBoxData(filterData)
        } else {
            setCheckBoxData((prev) => [...prev, email])
        }
    }

    const onDragEnd = (result) => {
        const { source, destination } = result;

        // If dropped outside the list
        if (!destination) {
            return;
        }

        const reorderedItems = Array.from(manageField);
        const [movedItem] = reorderedItems.splice(source.index, 1);
        reorderedItems.splice(destination.index, 0, movedItem);

        // Update the state with the new order
        setManageField(reorderedItems);


        const Url = `/default-manage-field/update-sequence`
        post(Url, { items: reorderedItems }).then((data) => {
            if (data?.status) {
                // toast.success(data?.message);  
                // setModalData({ 
                //     message: data?.message,
                //     show: true
                //   })
                getAllManageField()
            }
            else {
                toast.error(data?.message)
            }
        }).catch(err => {
            toast.error(err.response.data.error)
        })
    };

    const allCheck = (val) => {
        if (val) {
            let newData = []
            manageField?.map((item) => {
                newData.push(item.email)
            })
            setCheckBoxData(newData)

        } else {
            setCheckBoxData([])
        }
        setCheckedValue(!checkedValue)

    }

    useEffect(() => {
        if (manageField?.length > 0) {
            if (manageField?.length == checkBoxData?.length) {
                setCheckedValue(true)
            } else {
                setCheckedValue(false)

            }
        }

    }, [checkBoxData])


    return (
        <>
            <Row className="mt-7" >
                <Col className="mb-5 mb-xl-0 ml-5" xl="11" >
                    <Card className="shadow">
                        <CardHeader className="border-0">
                            <Row className="align-items-center " >
                                <div className="col">
                                    <h3 className="mb-0" style={{ textAlign: "left" }}>Manage Field</h3>
                                </div>
                                {/* {
                                    checkBoxData?.length > 0 &&
                                    <>

                                        <Button
                                            color="primary"
                                            onClick={() => setShowModel(true)}
                                        >
                                            All Reply
                                        </Button>
                                    </>
                                } */}
                                {/* <Link to={`/admin/template/manage-field/add?${id}`}> */}

                                <Button
                                    color="primary"
                                    href="#pablo"
                                    onClick={() => { setShowModel(true); setValueManageField("width", 100); setValueManageField("align", "left") }}
                                >
                                    Add Manage Field
                                </Button>
                                {/* </Link> */}
                            </Row>
                        </CardHeader>

                        <Row className="d-flex" style={{ justifyContent: "right" }}>
                            <div >
                                <Form onSubmit={handleSubmit(onSubmit)} >
                                    <Row>
                                        <Col md="6" >
                                            <FormGroup className="mb-3">
                                                <InputGroup className="input-group-alternative">
                                                    <input
                                                        placeholder="Searching......."
                                                        type="text"
                                                        autoComplete="new-email"
                                                        {...register("companyName")}
                                                        style={{ border: "1px solid" }}
                                                        className="form-control"
                                                    />
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>

                                        <Button color="success" type="submit" className="d-flex" >
                                            Search
                                        </Button>
                                        <Button color="danger" className="d-flex" onClick={clearSearch}
                                            style={{ marginRight: "40px" }}
                                        >
                                            Clear
                                        </Button>
                                    </Row>
                                </Form>
                            </div>
                        </Row>

                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable-table">
                                {(provided) => (
                                    <Table className="align-items-center table-flush" responsive>
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col" width="10%">Field Label</th>
                                                <th scope="col" width="10%">Field Type</th>
                                                <th scope="col" width="10%">Width</th>
                                                <th scope="col" width="10%">Align</th>
                                                <th scope="col" width="20%">Create Date</th>
                                                <th scope="col" width="10%">Status</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                            {manageField?.length > 0 ? (
                                                manageField.map((item, index) => (
                                                    <Draggable key={item._id} draggableId={item._id} index={index}>
                                                        {(provided) => (
                                                            <tr
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <th>{item?.fieldLabel}</th>
                                                                <th>{item?.fieldType}</th>
                                                                <th>{item?.width}</th>
                                                                <th>{item?.align}</th>
                                                                <th>{moment(item?.createdAt).format("MMMM DD,YYYY hh:mm A")}</th>
                                                                <td >
                                                                    {item?.isActive ? (
                                                                        <Button color="success" type="submit">
                                                                            Active
                                                                        </Button>
                                                                    ) : (
                                                                        <Button color="danger" type="submit">
                                                                            Deactive
                                                                        </Button>
                                                                    )}
                                                                </td>
                                                                <td >
                                                                    {item?.isActive ? (
                                                                        <i
                                                                            style={{ color: "red", width: "30px", fontSize: "20px" }}
                                                                            onClick={() => changeStatus(item._id, item?.isActive)}
                                                                            className="fa fa-times "
                                                                            aria-hidden="true"
                                                                            role="button"

                                                                        />
                                                                    ) : (
                                                                        <i
                                                                            style={{ color: "green", width: "30px", fontSize: "20px" }}
                                                                            onClick={() => changeStatus(item._id, item?.isActive)}
                                                                            className="fa fa-check"
                                                                            aria-hidden="true"
                                                                            role="button"
                                                                        />
                                                                    )}
                                                                    <i
                                                                        style={{ color: "black", width: "30px", fontSize: "20px" }}
                                                                        onClick={() => {
                                                                            setShowModel(true);
                                                                            setManageFieldData(item);
                                                                        }}
                                                                        className="fa fa-pencil-square-o"
                                                                        aria-hidden="true"
                                                                        role="button"

                                                                    />
                                                                    <i
                                                                        onClick={() => deleteHandler(item._id)}
                                                                        className="fa fa-trash"
                                                                        aria-hidden="true"
                                                                        style={{ color: "red", width: "30px", fontSize: "20px" }}
                                                                        role="button"

                                                                    />
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </Draggable>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="7" style={{ textAlign: 'center' }}>No Record Found</td>
                                                </tr>
                                            )}
                                            {provided.placeholder}
                                        </tbody>
                                    </Table>
                                )}
                            </Droppable>
                        </DragDropContext>

                        {/* <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col" width="10%">Field Label</th>
                                    <th scope="col" width="10%">Field Type</th>
                                    <th scope="col" width="10%">Width</th>
                                    <th scope="col" width="10%">Align</th>
                                    <th scope="col" width="20%">Create Date</th>
                                    <th scope="col" width="10%">Status</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    manageField?.length > 0 ?
                                        manageField.map((item, index) => {
                                            return (
                                                <tr key={item?._id}>
                                                    <td>
                                                        <input className="custom-control-input" style={{ opacity: 1, position: "revert" }} id={item?.email} type="checkbox" value={item.email} checked={checkBoxData?.includes(item.email)} onChange={() => { checkBoxHandler(item.email) }} />
                                                    </td>
                                                    <th >{item?.fieldLabel}</th>
                                                    <th >{item?.fieldType}</th>
                                                    <th >{item?.width}</th>
                                                    <th >{item?.align}</th>
                                                    <th >{moment(item?.createdAt).format("MMMM DD,YYYY hh:mm A")}</th>
                                                    <td>{item?.isActive ? <Button color="success" type="submit" className="">
                                                        Active
                                                    </Button> : <Button color="danger" type="submit" className="">
                                                        Deactive
                                                    </Button>}</td>
                                                    <td>
                                                        {
                                                            item?.isActive ?
                                                                <i style={{ color: "red", width: "30px", fontSize: "20px" }} onClick={() => changeStatus(item._id, item?.isActive)} class="fa fa-times" aria-hidden="true"></i> :
                                                                <i style={{ color: "green", width: "30px", fontSize: "20px" }} onClick={() => changeStatus(item._id, item?.isActive)} class="fa fa-check" aria-hidden="true"></i>
                                                        }
                                                        <i style={{ color: "black", width: "30px", fontSize: "20px" }} onClick={() => { setShowModel(true); setManageFieldData(item) }} class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                                        <i onClick={() => deleteHandler(item._id)} class="fa fa-trash" aria-hidden="true" style={{ color: "red", width: "30px", fontSize: "20px" }}></i>



                                                    </td>
                                                </tr>
                                            )
                                        })
                                        : "No Record Found"
                                }
                            </tbody>
                        </Table> */}
                        {/* {manageField?.length ?
                            <div className="pagination-centered">
                                <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={pageData?.page}
                                    itemsCountPerPage={pageData?.perPage}
                                    totalItemsCount={count}
                                    pageRangeDisplayed={5}
                                    onChange={changePage}
                                />
                            </div> : ''} */}
                    </Card >
                </Col >
            </Row >
            <Modal
                show={showModel}
                onHide={() => { setShowModel(false); setManageFieldData("") }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header >
                    <Modal.Title id="contained-modal-title-vcenter">
                        Manage Field
                    </Modal.Title>
                </Modal.Header>
                <form onSubmit={handleSubmitManageField(onSubmitManageField)}>
                    <Modal.Body>
                        <Row>
                            <Col md="12">
                                <FormGroup className="mb-3">

                                    <label className="d-flex">Field Label  </label>
                                    <InputGroup className="input-group-alternative">
                                        <input
                                            placeholder="Please Enter Field Label"
                                            type="text"
                                            {...registerManageField("fieldLabel", { required: true })}
                                            className="form-control border"
                                        />

                                    </InputGroup>
                                    {ErrorsManageField.fieldLabel && <span className="text-danger d-flex"> This field is required</span>}
                                </FormGroup>

                            </Col>
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <label className="d-flex">Field Type <span className="text-danger">*</span></label>
                                    <InputGroup className="input-group-alternative">
                                        <select
                                            id="role"
                                            name="role"
                                            className="block appearance-none w-full bg-white border form-control border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                                            {...registerManageField("fieldType", { required: true })}

                                        >
                                            <option selected value="text">Text</option>
                                            <option value="textarea">Textarea</option>
                                            <option value="select">Select</option>
                                            <option value="money">Money</option>
                                            <option value="file">File</option>
                                            <option value="checkbox">Checkbox</option>
                                        </select>
                                    </InputGroup>
                                    {ErrorsManageField.fieldType && <span className="text-danger d-flex"> This field is required</span>}
                                </FormGroup>

                            </Col>

                            <Col md="6">
                                <FormGroup className="mb-3">

                                    <label className="d-flex">Width  </label>
                                    <InputGroup className="input-group-alternative">
                                        <input
                                            placeholder="Please Enter Width"
                                            type="text"
                                            {...registerManageField("width", { required: true })}
                                            className="form-control border"
                                        />

                                    </InputGroup>
                                    {ErrorsManageField.width && <span className="text-danger d-flex"> This field is required</span>}
                                </FormGroup>

                            </Col>
                            {
                                (watch("fieldLabel")?.toLowerCase() == "quantity" || getValues("fieldLabel")?.toLowerCase() == "quantity") &&
                                <Col md="12">
                                    <FormGroup className="mb-3">

                                        <label className="d-flex">Value  </label>
                                        <InputGroup className="input-group-alternative">
                                            <input
                                                placeholder="Please Enter Default Value"
                                                type="text"
                                                {...registerManageField("defaultValueQuan", { required: false })}
                                                className="form-control border"
                                                defaultValue="1"
                                            />

                                        </InputGroup>
                                        {ErrorsManageField.defaultValueQuan && <span className="text-danger d-flex"> This field is required</span>}
                                    </FormGroup>

                                </Col>
                            }
                            {
                                watch("fieldType") == "select" && <>
                                    <Col md="12">
                                        <FormGroup className="mb-3">

                                            <label className="d-flex">Choices  </label>
                                            <InputGroup className="input-group-alternative">
                                                <textarea
                                                    placeholder="Please Enter Choices"
                                                    type="text"
                                                    {...registerManageField("choices", { required: watch("fieldType") == "select" ? true : false })}
                                                    className="form-control border"
                                                />

                                            </InputGroup>
                                            {ErrorsManageField.choices && <span className="text-danger d-flex"> This field is required</span>}
                                        </FormGroup>

                                    </Col>

                                    <Col md="12">
                                        <FormGroup className="mb-3">

                                            <label className="d-flex">Default Value  </label>
                                            <InputGroup className="input-group-alternative">
                                                <input
                                                    placeholder="Please Enter Default Value"
                                                    type="text"
                                                    {...registerManageField("defaultValue", { required: watch("fieldType") == "select" ? true : false })}
                                                    className="form-control border"
                                                />

                                            </InputGroup>
                                            {ErrorsManageField.defaultValue && <span className="text-danger d-flex"> This field is required</span>}
                                        </FormGroup>

                                    </Col>
                                </>
                            }

                            {
                                watch("fieldType") == "money" && <>
                                    {/* <Col md="12">
                                        <FormGroup className="mb-3">

                                            <label className="d-flex">Currency  </label>
                                            <InputGroup className="input-group-alternative">
                                                <textarea
                                                    placeholder="Please Enter Currency"
                                                    type="text"
                                                    {...registerManageField("currency", { required: watch("fieldType") == "money" ? true : false })}
                                                    className="form-control border"
                                                />

                                            </InputGroup>
                                            {ErrorsManageField.currency && <span className="text-danger d-flex"> This field is required</span>}
                                        </FormGroup>

                                    </Col> */}

                                    <Col md="12">
                                        <FormGroup className="mb-3">
                                            <label>Currency</label>

                                            <InputGroup className="input-group-alternative">
                                                <select
                                                    id="currency"
                                                    name="currency"
                                                    className="block appearance-none w-full bg-white border form-control border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                                                    {...registerManageField("currency", { required: watch("fieldType") == "money" ? true : false })}

                                                >
                                                    <option selected value="$">$</option>
                                                    {/* <option value="right">Right</option> */}

                                                </select>
                                                {ErrorsManageField.currency && <span className="text-danger d-flex"> This field is required</span>}
                                            </InputGroup>
                                        </FormGroup>

                                    </Col>
                                </>
                            }
                            {watch("fieldType") == "checkbox" && <>
                                <Col md="12">
                                    <FormGroup className="mb-3">
                                        <label>Default</label>

                                        <InputGroup className="input-group-alternative">
                                            <select
                                                id="DefaultCheck1"
                                                name="DefaultCheck1"
                                                className="block appearance-none w-full bg-white border form-control border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                                                {...registerManageField("defaultCheckedValue", { required: watch("fieldType") == "checkbox" ? true : false })}

                                            >
                                                <option value={true}>Yes</option>
                                                <option selected value={false}>No</option>

                                            </select>
                                            {ErrorsManageField.defaultCheckedValue && <span className="text-danger d-flex"> This field is required</span>}
                                        </InputGroup>
                                    </FormGroup>

                                </Col>
                            </>
                            }


                            <Col md="12">
                                <FormGroup className="mb-3">

                                    <label className="d-flex">Align  </label>
                                    <InputGroup className="input-group-alternative">
                                        <input
                                            placeholder="Please Enter Align"
                                            type="text"
                                            {...registerManageField("align", { required: true })}
                                            className="form-control border"
                                        />

                                    </InputGroup>
                                    {ErrorsManageField.align && <span className="text-danger d-flex"> This field is required</span>}
                                </FormGroup>

                            </Col>

                            {/* <Col md="12">

                                <FormGroup className="mb-3">
                                    <input className="custom-control-input" style={{ opacity: 1, position: "revert" }} id="123Default" type="checkbox" value={true}  {...registerManageField("isCollectionShort")} />
                                    <span style={{ marginLeft: "10px" }}>Check this to force your collection views to sort field like this.</span>
                                </FormGroup>

                            </Col> */}

                        </Row>
                    </Modal.Body>

                    <Modal.Footer>

                        <Button onClick={() => { setShowModel(false); setManageFieldData("") }}>Close</Button>
                        <Button type="submit">Submit</Button>
                    </Modal.Footer>
                </form>
            </Modal >
            <SuccessModal message={modalData?.message} showModal={modalData?.show} setShowModal={setModalData} />

        </>
    );
}

export default Index


