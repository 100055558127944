import './App.css';
import { BrowserRouter, Route, Routes, Navigate, useNavigate } from "react-router-dom";
import AdminLayout from "../src/layouts/Admin";
import AuthLayout from "../src/layouts/Auth";
import { Provider, useSelector } from 'react-redux';
import { store } from './reduxToolkit/store/store';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import AppRoute from './AppRoute';



function App() {




  return (
    <Provider store={store}>
      <div className="App">
        <AppRoute />
        {/* <BrowserRouter>
    <Routes>
 
        
            <Route path="/admin/*" element={<AdminLayout />} /> 
              <Route path="/auth/*" element={<AuthLayout />} />

      <Route path="*" element={<Navigate to="/auth/login" replace />} /> 
    </Routes>

      </BrowserRouter> */}
      </div>
      <ToastContainer position="top-right" />

    </Provider>

  );
}

export default App;
