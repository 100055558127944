import React, { useState } from "react";
import { Collapse, Nav, NavItem, NavLink } from "reactstrap";
import { NavLink as NavLinkRRD } from "react-router-dom";

const SideBarMenu = ({ prop }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeSubMenu, setActiveSubMenu] = useState(null);

    const toggle = () => setIsOpen(!isOpen);

    const toggleSub = (index) => {
        setActiveSubMenu(activeSubMenu === index ? null : index);
    };

    return (
        <div>
            {typeof prop.name !== "undefined" && (
                <Nav vertical>
                    {prop.subMenu && prop.subMenu.length > 0 ? (
                        <NavItem>
                            <NavLink
                                onClick={toggle}
                                className="dropdown-toggle"
                                style={{ color: isOpen ? "#c5272d" : "", cursor: "pointer" }}
                            >
                                {prop.name}
                            </NavLink>
                        </NavItem>
                    ) : (
                        <NavItem>
                            <NavLink
                                to={prop.layout + prop.path}
                                tag={NavLinkRRD}
                            >
                                {prop.name}
                            </NavLink>
                        </NavItem>
                    )}

                    {/* Second Level */}
                    {prop.subMenu &&
                        prop.subMenu.map((subProp, subKey) => {
                            return (
                                <Collapse isOpen={isOpen} key={subKey}>
                                    <Nav vertical>
                                        <NavItem>
                                            <NavLink
                                                onClick={() => toggleSub(subKey)}
                                                style={{ color: activeSubMenu === subKey ? "#c5272d" : "", cursor: "pointer" }}
                                                to={subProp?.subMenu?.length > 0 ? "#" : subProp.layout + subProp.path}
                                                tag={subProp?.subMenu?.length > 0 ? "a" : NavLinkRRD}
                                                className={subProp?.subMenu?.length > 0 && "   dropdown-toggle"}
                                            >
                                                - {subProp.name}
                                            </NavLink>
                                        </NavItem>
                                        {/* Third Level */}
                                        {subProp.subMenu && subProp.subMenu.length > 0 && (
                                            <Collapse isOpen={activeSubMenu === subKey} key={subKey}>
                                                <Nav vertical>
                                                    {subProp.subMenu.map((subSubProp, subSubKey) => (
                                                        <NavItem key={subSubKey}>
                                                            <NavLink to={subSubProp.layout + subSubProp.path} tag={NavLinkRRD}>
                                                                -- {subSubProp.name}
                                                            </NavLink>
                                                        </NavItem>
                                                    ))}
                                                </Nav>
                                            </Collapse>
                                        )}
                                    </Nav>
                                </Collapse>
                            );
                        })}
                </Nav>
            )}
        </div>
    );
};

export default SideBarMenu;
