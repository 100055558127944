import React , {useEffect,useState} from "react";


// reactstrap components
import {
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Button,
  Card,
  CardHeader
} from "reactstrap";
import Header from "../../components/Headers/Header";
import {Link,useLocation,useNavigate} from 'react-router-dom';
import { useForm } from "react-hook-form";
import { post,get } from "../../utils/apiManager";
import { toast } from "react-toastify";
import { baseURL } from "../../config/config";
import CKEditor from 'ckeditor4-react';
import Editor from "../../components/Editor";




const Add = () => {
    const { register, handleSubmit, watch, formState: { errors },setValue } = useForm();
    const Navigate = useNavigate()
    const location = useLocation()
    const [editorData,setEditorData] = useState()


    const onSubmit = (data) => {
        
        const Url = `${baseURL}/home-page/add`       
        if(editorData == ""){
            toast.error("description field is required") 
            return 
        }
      

         let formData = new FormData();
         formData.append("name",data?.name)
        //  formData.append("pageName",data?.pageName)
         formData.append("title",data?.title)
         formData.append("subtitle",data?.subtitle)
         formData.append("image",data?.image?.[0])
         formData.append("description",editorData)

        post(Url, formData,1).then((data) => {  
          if (data?.status) {
          // toast.success(data?.message);
          Navigate("/admin/home-page")
            
        }
        else {
    
          toast.error(data?.message)
        }}).catch(err=>{
          toast.error(err.response.data.error)
    
        })
       
      }


    return (
      <>
      {/* <Header /> */}
       {/* <Link to="/admin/user/index">
            <Button color="primary" type="button" className="mt-3" style={{position:"absolute",left:0}}>
           Back
        </Button>
        </Link> */}
             <Row className="mt-7" >
          <Col className="mb-5 mb-xl-0 ml-5" xl="11" >
            <Card className="shadow">
              <CardHeader className="border-0">
        <div className="d-flex">
                <h2 style={{textAlign:"left",marginTop:"10px"}}>Home Page Add</h2>

                <Button  className='bi-trash justify-content-right' style={{position
                :"absolute",right:0,marginRight:"40px"}} onClick={() => Navigate(`/admin/home-page`)} color="primary">Back</Button>
                </div>
        <Form className="" style={{marginTop:"40px"}} onSubmit={handleSubmit(onSubmit)}>
          <Row>
          

           
            {/* <Col md="4">
            <FormGroup className="mb-3">
            <label className="d-flex">Page Name <span className="text-danger">*</span></label>

                <InputGroup className="input-group-alternative">
             
                  
                  <input
                    placeholder="Page Name"
                    type="text"
                    {...register("pageName", { required: false })}
                    className="form-control border"
                  />

                </InputGroup>
                {errors.pageName && <span className="text-danger d-flex"> This field is required</span>}
              </FormGroup>
            </Col> */}

            <Col md="4">
            <FormGroup className="mb-3">
            <label className="d-flex">Name  <span className="text-danger">*</span></label>

                <InputGroup className="input-group-alternative">
                  {/* <InputGroupAddon addonType="prepend"> */}
                  {/* </InputGroupAddon> */}

                  <select
                          id="role"
                          name="role"
                          className="block appearance-none w-full bg-white border form-control border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                          {...register("name", { required: "This field is required" })}
                      
                      >
                          <option value="" disabled selected>Select</option>
                          <option value="features">Features</option>
                          <option value="about us">About Us</option>
                          <option value="banner">Banner</option>
                        </select>
                  
                  {/* <input
                    placeholder="name"
                    type="text"
                    autoComplete="new-email"
                    {...register("name", { required: true })}
                    className="form-control border"
                  /> */}

                </InputGroup>
                {errors.name && <span className="text-danger d-flex"> This field is required</span>}
              </FormGroup>
            </Col>


            <Col md="4">
            <FormGroup className="mb-3">
            <label className="d-flex">Title <span className="text-danger">*</span></label>

                <InputGroup className="input-group-alternative">
                  {/* <InputGroupAddon addonType="prepend"> */}
                  {/* </InputGroupAddon> */}
                  
                  <input
                    placeholder="Title"
                    type="text"
                    autoComplete="new-email"
                    {...register("title", { required: true })}
                    className="form-control border"
                  />

                </InputGroup>
                {errors.title && <span className="text-danger d-flex"> This field is required</span>}
              </FormGroup>
            </Col>

            <Col md="4">
            <FormGroup className="mb-3">
            <label className="d-flex">Sub Title </label>

                <InputGroup className="input-group-alternative">
                  {/* <InputGroupAddon addonType="prepend"> */}
                  {/* </InputGroupAddon> */}
                  
                  <input
                    placeholder="Sub Title"
                    type="text"
                    autoComplete="new-email"
                    {...register("subtitle", { required: false })}
                    className="form-control border"
                  />

                </InputGroup>
                {errors.subtitle && <span className="text-danger d-flex"> This field is required</span>}
              </FormGroup>
            </Col>
         
            <Col md="4">
            <FormGroup className="mb-3">
            <label className="d-flex">Image </label>

                <InputGroup className="input-group-alternative">
                  {/* <InputGroupAddon addonType="prepend"> */}
                  {/* </InputGroupAddon> */}
                  <input
                    placeholder="Please Select Image"
                    type="file"
                    {...register("image", { required: false })}
                    className="form-control border"
                  />

                </InputGroup>
                {errors.image && <span className="text-danger d-flex"> This field is required</span>}
              </FormGroup>
            </Col>
            <Col md="12">
             <Editor editorData={editorData} setEditorData={setEditorData} />
      </Col>
          </Row>

          <Button color="primary" type="submit" className="d-flex" style={{marginTop:"10px"}} >
           Submit
        </Button>
          
        </Form>
        </CardHeader>
        </Card>
        </Col>
        </Row>
      </>
    );
  
}

export default Add;