import React, { useState } from "react";


// reactstrap components
import {
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Button,
  Card,
  CardHeader
} from "reactstrap";
import Header from "../../components/Headers/Header";
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { post } from "../../utils/apiManager";
import { toast } from "react-toastify";
import { baseURL } from "../../config/config";
import { State, City } from 'country-state-city';
import PhoneInput from "../../components/Auth/PhoneNumber";



const Add = () => {
  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
  const Navigate = useNavigate()
  const allStates = State.getStatesOfCountry('US')
  const [cities, setCities] = useState([])
  const onSubmit = (data) => {

    const Url = `${baseURL}/company/create`
    if (data?.state != "") {
      if (JSON?.parse(data?.state)) {
        data.state = JSON?.parse(data?.state)?.name
      }
    }
    post(Url, data).then((data) => {
      if (data?.status) {
        // toast.success(data?.message);
        Navigate("/admin/user")

      }
      else {

        toast.error(data?.message)
      }
    }).catch(err => {
      toast.error(err.response.data.error)

    })

  }

  const stateChangeHandler = (state) => {
    if (state != "") {
      let { isoCode } = JSON.parse(state)
      const citiesInState = City.getCitiesOfState('US', isoCode);
      setCities(citiesInState)
    }

  }


  return (
    <>
      {/* <Header /> */}
      {/* <Link to="/admin/user/index">
            <Button color="primary" type="button" className="mt-3" style={{position:"absolute",left:0}}>
           Back
        </Button>
        </Link> */}
      <Row className="mt-7">
        <Col className="mb-5 mb-xl-0 ml-5" xl="11">
          <Card className="shadow">
            <CardHeader className="border-0">
              <div className="d-flex">
                <h2 style={{ textAlign: "left", marginTop: "10px" }}>Company Detail Add</h2>
                <Button
                  className='bi-trash justify-content-right'
                  style={{ position: "absolute", right: 0, marginRight: "40px" }}
                  onClick={() => Navigate(`/admin/user`)}
                  color="primary"
                >
                  Back
                </Button>
              </div>
              <Form style={{ marginTop: "40px" }} onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col md="4">
                    <FormGroup className="mb-3">
                      <label className="d-flex">First Name <span className="text-danger">*</span></label>
                      <InputGroup className="input-group-alternative">
                        <input
                          placeholder="First Name"
                          type="text"
                          autoComplete="new-email"
                          {...register("firstName", { required: true })}
                          className="border form-control"

                        />
                      </InputGroup>
                      {errors.firstName && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Last Name <span className="text-danger">*</span></label>
                      <InputGroup className="input-group-alternative">
                        <input
                          placeholder="Last Name"
                          type="text"
                          autoComplete="new-email"
                          {...register("lastName", { required: true })}
                          className="border form-control"
                        />
                      </InputGroup>
                      {errors.lastName && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Company Name <span className="text-danger">*</span></label>
                      <InputGroup className="input-group-alternative">
                        <input
                          placeholder="Company Name"
                          type="text"
                          autoComplete="new-email"
                          {...register("company_name", { required: true })}
                          className="border form-control"
                        />
                      </InputGroup>
                      {errors.company_name && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Phone <span className="text-danger">*</span></label>
                      {/* <InputGroup className="input-group-alternative">
                        <input
                          placeholder="Phone"
                          type="text"
                          autoComplete="new-email"
                          {...register("phone", {
                            required: "This field is required",
                            pattern: {
                              value: /^[0-9]+$/, 
                              message: 'only phone numbers allowed'
                            }
                          })}
                          className="border form-control"
                        /> */}
                      <PhoneInput name="phone" placeholder="Phone" watch={watch} setValue={setValue} errors={errors} register={register} />

                      {/* </InputGroup> */}
                      {errors.phone && <span className="text-danger d-flex"> {errors?.phone?.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Cell Phone <span className="text-danger">*</span></label>
                      {/* <InputGroup className="input-group-alternative">
                        <input
                          placeholder="Cell Phone"
                          type="text"
                          autoComplete="new-email"
                          {...register("cell_phone", {
                            required: "This field is required", pattern: {
                              value: /^[0-9]+$/,
                              message: 'only phone numbers allowed'
                            }
                          })}
                          className="border form-control"
                        /> */} 
                      <PhoneInput name="cell_phone" placeholder="Cell Phone" watch={watch} setValue={setValue} errors={errors} register={register} />
                      {/* </InputGroup> */}
                      {errors.cell_phone && <span className="text-danger d-flex"> {errors?.cell_phone?.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Email <span className="text-danger">*</span></label>
                      <InputGroup className="input-group-alternative">
                        <input
                          placeholder="Email"
                          type="email"
                          autoComplete="new-email"
                          {...register("email", { required: true })}
                          className="border form-control"
                        />
                      </InputGroup>
                      {errors.email && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  </Col>


                </Row>
                <Row>
                  <Col md="4">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Address <span className="text-danger">*</span></label>
                      <InputGroup className="input-group-alternative">
                        <textarea
                          id="address"
                          name="address"
                          placeholder="Enter address"
                          {...register("address", { required: "This field is required" })}
                          className="mt-1 block w-full px-3 py-2 form-control border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"

                        />
                      </InputGroup>
                      {errors.address && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  </Col>


                  <Col md="4">
                    <FormGroup className="mb-3">
                      <label className="d-flex">State <span className="text-danger">*</span></label>
                      <InputGroup className="input-group-alternative">
                        <select
                          className="form-control border"
                          id="floatingSelect"
                          aria-label="Floating label select example"
                          {...register('state', {
                            required: 'This field is required',
                            onChange: (e) => stateChangeHandler(e.target.value)
                          },

                          )}
                        // onChange={(e) => stateChangeHandler(e.target.value)}
                        >
                          <option selected="" value="">Select State</option>
                          {
                            allStates?.map((val, index) => {
                              return (
                                <option key={index} value={JSON.stringify(val)}>{val?.name}</option>
                              )
                            })
                          }

                        </select>
                      </InputGroup>

                      {errors.state && <span className="text-danger d-flex">This field is required</span>}
                      {/* <input
                          placeholder="State"
                          type="text"
                          autoComplete="new-email"
                          {...register("state", { required: true })}
                          className="border form-control"
                        />
                      </InputGroup>
                      {errors.state && <span className="text-danger d-flex"> This field is required</span>} */}
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup className="mb-3">
                      <label className="d-flex">City <span className="text-danger">*</span></label>
                      <InputGroup className="input-group-alternative">
                        {/* <input
                          placeholder="City"
                          type="text"
                          autoComplete="new-email"
                          {...register("city", { required: true })}
                          className="border form-control"
                        />
                      {errors.city && <span className="text-danger d-flex"> This field is required</span>} */}
                        <select
                          className="form-control border"
                          id="floatingSelect"
                          aria-label="Floating label select example"
                          {...register('city', {
                            required: 'This field is required',
                          })}
                        >
                          <option selected="" value="">Select city</option>
                          {
                            cities?.map((val, index) => {
                              return (
                                <option key={index} value={val?.name}>{val?.name}</option>

                              )
                            })
                          }


                        </select>
                      </InputGroup>

                      {errors.city && <span className="text-danger d-flex">{errors.city.message}</span>}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Zip Code <span className="text-danger">*</span></label>
                      <InputGroup className="input-group-alternative">
                        <input
                          placeholder="Zip Code"
                          type="text"
                          autoComplete="new-email"
                          {...register("zip_code", { required: true })}
                          className="border form-control"
                        />
                      </InputGroup>
                      {errors.zip_code && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Website </label>
                      <InputGroup className="input-group-alternative">
                        <input
                          placeholder="Website"
                          type="text"
                          autoComplete="new-email"
                          {...register("website", { required: false })}
                          className="border form-control"
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Company Type <span className="text-danger">*</span></label>
                      <InputGroup className="input-group-alternative">
                        <select
                          id="role"
                          name="role"
                          className="block appearance-none w-full bg-white border form-control border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                          {...register("companyType", { required: "This field is required" })}

                        >
                          <option value="" disabled selected>Select your Company Type</option>
                          <option value="Auction">Auction</option>
                          <option value="Estate Sales">Estate Sales</option>
                          <option value="Other Bussiness">Other Bussiness</option>
                          <option value="Private Estate Sales">Private Estate Sales</option>
                        </select>
                      </InputGroup>
                      {errors.companyType && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Apt Suite </label>
                      <InputGroup className="input-group-alternative">
                        <input
                          placeholder="Apt Suite"
                          type="text"
                          autoComplete="new-email"
                          {...register("address_apt_suite", { required: false })}
                          className="border form-control"
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Button color="primary" type="submit" className="d-flex">
                  Submit
                </Button>
              </Form>
            </CardHeader>
          </Card>
        </Col>
      </Row>
    </>
  );

}

export default Add;