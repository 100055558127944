import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  UncontrolledTooltip,
  Container,
  Button,
  Row,
  Col,
  Card,
  CardHeader
} from "reactstrap";
import Header from "../../components/Headers/Header";
import { Link, useNavigate } from 'react-router-dom';
import { get, post } from "../../utils/apiManager";
import { baseURL } from "../../config/config";
import { toast } from "react-toastify";
import { useLocation } from 'react-router-dom';



const ViewOne = () => {

  const location = useLocation()
  const [emailTemplate, setEmailTemplate] = useState({})
  const Navigate = useNavigate()

  useEffect(() => {
    if (location?.search) {

      getOneEmailTemplate(location?.search?.split("?")[1])
    }
  }, [])
 

  const getOneEmailTemplate = (id) => {
    get(`/company/email/${id}`).then((res) => {
      setEmailTemplate(res?.datas)

    }).catch((error) => {
      console.log(error, "erre");
    })
  }



 


  return (
    <>
      {/* <Header /> */}

      <Row className="mt-7">
        <Col className="mb-5 mb-xl-0 ml-5" xl="11" >
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <div className="col">
                  <h3 className="mb-0" style={{ textAlign: "left" }}>View Email Templte</h3>
                  <Button className='bi-trash justify-content-right' style={{
                    position
                      : "absolute", right: 0, marginRight: "40px" 
                  }} onClick={() => Navigate(-1)} color="primary">Back</Button>
                </div>

              </Row>
            </CardHeader>
            <div style={{ textAlign: "left", marginLeft: "30px" }}>
              <p>Title : {emailTemplate?.title}</p><br />
              <p>Slug : {emailTemplate?.slug}</p><br />
              <p>Subject : {emailTemplate?.subject}</p><br />
              <p>Description :   <p dangerouslySetInnerHTML={{ __html: emailTemplate?.description }} />
                {/* {emailTemplate?.description} */}
              </p><br />
              {/* <p>Status :{emailTemplate?.isActive ? <Button color="success" type="submit" className="ml-2">
                Active
              </Button> : <Button color="danger" type="submit" className="ml-2">
                Deactive
              </Button>}</p><br /> */}
 

            </div>
          </Card>
        </Col>


      </Row>
    </>
  );

}

export default ViewOne