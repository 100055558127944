
import { useEffect, useState } from "react"
import { InputGroup } from "reactstrap";


const PhoneInput = ({ register, errors, setValue, name, watch, readOnly, placeholder}) => {

  const formatPhoneNumber = (value) => {
    if (!value) return value;

    const phoneNumber = value.toString().replace(/[^\d]/g, '');
 
    if (phoneNumber.length > 3 && phoneNumber.length <= 6) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    } else if (phoneNumber.length > 6) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    } else { 
      return phoneNumber;
    }
  };  
   
  const phoneNumber = watch(name);
  useEffect(() => {
    const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
    if (formattedPhoneNumber !== phoneNumber) {
      setValue(name, formattedPhoneNumber);
    }
  }, [phoneNumber, setValue]); 

 
  return (
    <InputGroup className="input-group-alternative">
    <input
      type="text"
      className={`form-control ${errors?.name ? `is-invalid` : ""}`}
      id="floatingInput"
      readOnly={readOnly ? readOnly : false}
      placeholder={placeholder}
      {...register(`${name}`, {
        required: 'This field is required',
        pattern: {
          value: /^[0-9\s\-]+$/,
          message: 'Only phone numbers are allowed'
        }
      })}
    />    
    </InputGroup>
  )              
}    
 
export default PhoneInput 