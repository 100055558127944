import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  // InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { baseURL } from '../config/config';
import { post } from "../utils/apiManager";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";


const Register = () => {

  const { register:registerUser, handleSubmit, watch, formState: { errors } } = useForm(
 );
 const Navigate = useNavigate()

const onSubmit = (data) => {

  // let {name,email,password} = data

  // const newData = {
  //    name:"one212",
  //    email:"one123@yopmail.com",
  //    password:"Harsh@123"
  // }
  
  const Url = `${baseURL}/admin/sign-up`
  post(Url, data).then((data) => handleSignUp(data));
  const handleSignUp = (data) => {
    if (data?.status) {
      toast.success(data?.message);
      Navigate("/auth/login")
    }
    else {
      toast.error(data?.message)
    }
  };
}

  return (
    <>
      <Col lg="6" md="8">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5">
            <div className="text-muted text-center mt-2 mb-4">
              <small>Sign up with</small>
            </div>
            <div className="text-center">
              <Button
                className="btn-neutral btn-icon mr-4"
                color="default"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <span className="btn-inner--icon">
                  <img
                    alt="..."
                    src={
                      require("../assets/img/icons/common/github.svg")
                        .default
                    }
                  />
                </span>
                <span className="btn-inner--text">Github</span>
              </Button>
              <Button
                className="btn-neutral btn-icon"
                color="default"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <span className="btn-inner--icon">
                  <img
                    alt="..."
                    src={
                      require("../assets/img/icons/common/google.svg")
                        .default
                    }
                  />
                </span>
                <span className="btn-inner--text">Google</span>
              </Button>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Or sign up with credentials</small>
            </div>
            <Form role="form" onSubmit={handleSubmit(onSubmit)}>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  {/* <InputGroupAddon addonType="prepend"> */}
                    <InputGroupText>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  {/* </InputGroupAddon> */}
                  <input placeholder="Name" type="text" name="name"  {...registerUser("name", { required: "This field is required" })}/>
                 
                </InputGroup>
              </FormGroup>
              {errors.name && <span className="text-danger d-flex"> This field is required</span>}

              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  {/* <InputGroupAddon addonType="prepend"> */}
                    <InputGroupText> 
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  {/* </InputGroupAddon> */}
                  <input
                    placeholder="Email"
                    type="email"
                    name="email"
                    // autoComplete="new-email"
                    {...registerUser("email", { required: "This field is required" })}
                  />
                   
                </InputGroup>
              </FormGroup>
              {errors.email && <span className="text-danger d-flex"> This field is required</span>}
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  {/* <InputGroupAddon addonType="prepend"> */}
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  {/* </InputGroupAddon> */}
                  <input
                    placeholder="Password"
                    type="password"
                    name="password"
                    {...registerUser("password", { required: "This field is required" })}
                  />
                 

                </InputGroup>
              </FormGroup>
              {errors.password && <span className="text-danger d-flex"> This field is required</span>}

              {/* <div className="text-muted font-italic">
                <small>
                  password strength:{" "}
                  <span className="text-success font-weight-700">strong</span>
                </small>
              </div> */}
              {/* <Row className="my-4">
                <Col xs="12">
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="customCheckRegister"
                      type="checkbox"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheckRegister"
                    >
                      <span className="text-muted">
                        I agree with the{" "}
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          Privacy Policy
                        </a>
                      </span>
                    </label>
                  </div>
                </Col>
              </Row> */}
              <div className="text-center">
                <Button className="mt-4" color="primary" type="submit">
                  Create account
                </Button>
                {/* <button type="submit" className="mt-4" color="primary" onClick={onSubmit}>Create account</button> */}
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Register;
