
import Index from "./views/Index.js";
import Dashboard from "./views/Dashboard.js"
import Profile from "./views/Profile.js";
import Maps from "./views/Maps.js";
import Register from "./views/Register.js";
import Login from "./views/Login.js";
import UserAdd from "./views/User/Add.js";
import UserIndex from "./views/User/Index.js";

import UserEdit from "./views/User/Edit.js";
import UserOne from "./views/User/ViewOne.js";
import StaffIndex from "./views/Staff/Index.js"
import StaffAdd from "./views/Staff/Add.js"
import StaffEdit from "./views/Staff/Edit.js"
import StaffOne from "./views/Staff/ViewOne.js"


import ForgotPassword from "./views/forgotPassword.js";
import ForgotPasswordOtp from "./views/Otp.js"
import ResetPasswordOtp from "./views/newPassword.js"
import EmailTemplateView from "./views/Email/Index.js"
import EmailTemplateViewOne from "./views/Email/ViewOne.js"
import EmailTemplateEdit from "./views/Email/Edit.js"
import EmailTemplateAdd from "./views/Email/Add.js"
import HomePage from "./views/HomePage/Index.js"
import HomePageAdd from "./views/HomePage/Add.js"
import HomePageEdit from "./views/HomePage/Edit.js"
import HomePageView from "./views/HomePage/View.js"
import Faq from "./views/Faq/Index.js"
import FaqAdd from "./views/Faq/Add.js"
import FaqEdit from "./views/Faq/Edit.js"
import FaqView from "./views/Faq/View.js"

import Blog from "./views/Blog/Index.js"
import BlogAdd from "./views/Blog/Add.js"
import BlogEdit from "./views/Blog/Edit.js"
import BlogView from "./views/Blog/View.js"

import Category from "./views/Category/Index.js"
import CategoryAdd from "./views/Category/Add.js"
import CategoryEdit from "./views/Category/Edit.js"
import CategoryView from "./views/Category/View.js"


import SubCategory from "./views/Subcategory/Index.js"
import SubCategoryAdd from "./views/Subcategory/Add.js"
import SubCategoryEdit from "./views/Subcategory/Edit.js"
import SubCategoryView from "./views/Subcategory/View.js"

import Pricing from "./views/Pricing/Index.js"
import PricingAdd from "./views/Pricing/Add.js"
import PricingEdit from "./views/Pricing/Edit.js"
import PricingView from "./views/Pricing/View.js"

import Setting from "./views/Setting/Setting.js"
import CommingSoonModel from "./components/CommingSoonModel.js";
import ChangePassword from "./views/ChangePassword.js";
import NewsLetter from "./views/Newsletter/Index"
import NewsLetterAdd from "./views/Newsletter/Add"
import NewsLetterEdit from "./views/Newsletter/Edit"

import ContactUs from "./views/ContactUs/Index"
import ContactUsView from "./views/ContactUs/View"

import SaleType from "./views/SaleType/Index"
import SaleTypeAdd from "./views/SaleType/Add"
import SaleTypeEdit from "./views/SaleType/Edit"

import SaleCategory from "./views/SaleCategory/Index"
import SaleCategoryAdd from "./views/SaleCategory/Add"
import SaleCategoryEdit from "./views/SaleCategory/Edit"


import DefaultTemplate from "./views/DefaultTemplate/Index"
import DefaultTemplateAdd from "./views/DefaultTemplate/Add"
import DefaultTemplateEdit from "./views/DefaultTemplate/Edit"


import CompanyEmailTemplate from "./views/EmailTemplate/Index"
import CompanyEmailTemplateAdd from "./views/EmailTemplate/Add"
import CompanyEmailTemplateEdit from "./views/EmailTemplate/Edit"
import CompanyEmailTemplateView from "./views/EmailTemplate/ViewOne.js"
 
 
import ManageField from "./views/DefaultManageField/Index"
import ManageFieldAdd from "./views/DefaultManageField/Add"
import ManageFieldEdit from "./views/DefaultManageField/Edit"



var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: <Dashboard />,
    layout: "/admin",
  },
  {
    path: "/user",
    name: "User Management",
    icon: "ni ni-tv-2 text-primary",
    component: <UserIndex />,
    layout: "/admin",
    subMenu: [
      {
        path: "/user",
        name: "Companies",
        icon: "ni ni-tv-2 text-primary",
        component: <UserIndex />,
        layout: "/admin",
      },
      // {
      //   path: "/staff",
      //   name: "Staff",
      //   icon: "ni ni-tv-2 text-primary",
      //   component: <StaffIndex />,
      //   layout: "/admin",
      // },

    ]
  },
  {
    path: "/user",
    name: "Sale Management",
    icon: "ni ni-tv-2 text-primary",
    component: <UserIndex />,
    layout: "/admin",
    subMenu: [
      {
        path: "/sale-type",
        name: "Sale Type Management",
        icon: "ni ni-tv-2 text-primary",
        component: <SaleType />,
        layout: "/admin",
      },
      {
        path: "/category",
        name: "Categories",
        icon: "ni ni-tv-2 text-primary",
        component: <Category />,
        layout: "/admin",
      },
      {
        path: "/default-template",
        name: "Template Management",
        icon: "ni ni-tv-2 text-primary",
        component: <DefaultTemplate />,
        layout: "/admin",
      },

    ]
  },

  {
    path: "/default-template",
    // name: "Template Management",
    icon: "ni ni-tv-2 text-primary",
    component: <DefaultTemplate />,
    layout: "/admin",
  },

  {
    path: "/default-template/add",
    // name: "Template Management",
    icon: "ni ni-tv-2 text-primary",
    component: <DefaultTemplateAdd />,
    layout: "/admin",
  },


  {
    path: "/default-template/edit",
    // name: "Template Management",
    icon: "ni ni-tv-2 text-primary",
    component: <DefaultTemplateEdit />,
    layout: "/admin",
  },

  //new
  
  {
    path: "/email-template",
    // name: "Template Management",
    icon: "ni ni-tv-2 text-primary",
    component: <CompanyEmailTemplate />,
    layout: "/admin",
  },

  {
    path: "/email-template/add",
    // name: "Template Management",
    icon: "ni ni-tv-2 text-primary",
    component: <CompanyEmailTemplateAdd />,
    layout: "/admin",
  },
 
  {
    path: "/email-template/view",
    // name: "Template Management",
    icon: "ni ni-tv-2 text-primary",
    component: <CompanyEmailTemplateView />,
    layout: "/admin",
  },
 
  {
    path: "/email-template/edit",
    // name: "Template Management",
    icon: "ni ni-tv-2 text-primary",
    component: <CompanyEmailTemplateEdit />,
    layout: "/admin",
  },
  
  // {
  //   path: "/sale-type",
  //   name: "Sale Type Management",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: <SaleType />,
  //   layout: "/admin",
  // },
  {
    path: "/sale-type",
    // name: "Sale Type Management",
    icon: "ni ni-tv-2 text-primary",
    component: <SaleType />,
    layout: "/admin",
  },
  {
    path: "/sale-type/add",
    // name: "Sale Type Management",
    icon: "ni ni-tv-2 text-primary",
    component: <SaleTypeAdd />,
    layout: "/admin",
  },
  {
    path: "/sale-type/edit",
    // name: "Sale Type Management",
    icon: "ni ni-tv-2 text-primary",
    component: <SaleTypeEdit />,
    layout: "/admin",
  },


  // {
  //   path: "/sale-category",
  //   name: "Sale Category",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: <SaleCategory />,
  //   layout: "/admin",
  // },
  {
    path: "/sale-category",
    // name: "Sale Type Management",
    icon: "ni ni-tv-2 text-primary",
    component: <SaleCategory />,
    layout: "/admin",
  },
  {
    path: "/sale-category/add",
    // name: "Sale Type Management",
    icon: "ni ni-tv-2 text-primary",
    component: <SaleCategoryAdd />,
    layout: "/admin",
  },
  {
    path: "/sale-category/edit",
    // name: "Sale Type Management",
    icon: "ni ni-tv-2 text-primary",
    component: <SaleCategoryEdit />,
    layout: "/admin",
  },

  {
    path: "/staff",
    // name: "Staff",
    icon: "ni ni-tv-2 text-primary",
    component: <StaffIndex />,
    layout: "/admin",
  },
  {
    path: "/profile",
    name: "Profile",
    icon: "ni ni-tv-2 text-primary",
    component: <Profile />,
    layout: "/admin",
  },



  {
    path: "/category/add",
    // name: "User Management",
    icon: "ni ni-tv-2 text-primary",
    component: <CategoryAdd />,
    layout: "/admin",
  },
  {
    path: "/category/edit",
    // name: "User Management",
    icon: "ni ni-tv-2 text-primary",
    component: <CategoryEdit />,
    layout: "/admin",
  },
  {
    path: "/category/view",
    // name: "User Management",
    icon: "ni ni-tv-2 text-primary",
    component: <CategoryView />,
    layout: "/admin",
  },

  {
    path: "/category/sub-categories",
    // name: "Categories",
    icon: "ni ni-tv-2 text-primary",
    component: <SubCategory />,
    layout: "/admin",
  },
  {
    path: "/sub-category/add",
    // name: "User Management",
    icon: "ni ni-tv-2 text-primary",
    component: <SubCategoryAdd />,
    layout: "/admin",
  },
  {
    path: "/category/sub-category/edit",
    // name: "User Management",
    icon: "ni ni-tv-2 text-primary",
    component: <SubCategoryEdit />,
    layout: "/admin",
  },
  {
    path: "/category/sub-category/view",
    // name: "User Management",
    icon: "ni ni-tv-2 text-primary",
    component: <SubCategoryView />,
    layout: "/admin",
  },


  {
    path: "/subscribes",
    name: "Plans",
    icon: "ni ni-tv-2 text-primary",
    component: <CommingSoonModel />,
    layout: "/admin",
    subMenu: [
      {
        path: "/subscribes",
        name: "Subscribers",
        icon: "ni ni-tv-2 text-primary",
        component: <CommingSoonModel />,
        layout: "/admin",
      },

      {
        path: "/subscriptions",
        name: "Subscriptions",
        icon: "ni ni-tv-2 text-primary",
        component: <CommingSoonModel />,
        layout: "/admin",
      },

      {
        path: "/pricing",
        name: "Manage Plans",
        icon: "ni ni-tv-2 text-primary",
        component: <Pricing />,
        layout: "/admin",
      },
      {
        path: "/configuration",
        name: "Configuration",
        icon: "ni ni-tv-2 text-primary",
        component: <CommingSoonModel />,
        layout: "/admin",
      },
    ]
  },


  {
    path: "/subscribes",
    // name: "Subscribers",
    icon: "ni ni-tv-2 text-primary",
    component: <CommingSoonModel />,
    layout: "/admin",
  },

  {
    path: "/subscriptions",
    // name: "Subscriptions",
    icon: "ni ni-tv-2 text-primary",
    component: <CommingSoonModel />,
    layout: "/admin",
  },


  {
    path: "/configuration",
    // name: "Configuration",
    icon: "ni ni-tv-2 text-primary",
    component: <CommingSoonModel />,
    layout: "/admin",
  },

  {
    path: "/plan",
    // name: "Subscribers",
    icon: "ni ni-tv-2 text-primary",
    component: <Profile />,
    layout: "/admin",
  },

  {
    path: "/plan",
    // name: "Subscriptions",
    icon: "ni ni-tv-2 text-primary",
    component: <Profile />,
    layout: "/admin",
  },

  {
    path: "/pricing",
    // name: "Manage Plans",
    icon: "ni ni-tv-2 text-primary",
    component: <Pricing />,
    layout: "/admin",
  },
  {
    path: "/pricing/view",
    // name: "Configuration",
    icon: "ni ni-tv-2 text-primary",
    component: <PricingView />,
    layout: "/admin",
  },

  {
    path: "/pricing/add",
    // name: "User Management",
    icon: "ni ni-tv-2 text-primary",
    component: <PricingAdd />,
    layout: "/admin",
  },
  {
    path: "/pricing/edit",
    // name: "User Management",
    icon: "ni ni-tv-2 text-primary",
    component: <PricingEdit />,
    layout: "/admin",
  },
  {
    path: "/pricing/view",
    // name: "User Management",
    icon: "ni ni-tv-2 text-primary",
    component: <PricingView />,
    layout: "/admin",
  },



  {
    path: "/user",
    name: "Marketing",
    icon: "ni ni-tv-2 text-primary",
    component: <UserIndex />,
    layout: "/admin",
    subMenu: [
      {
        path: "/options",
        name: "Options",
        icon: "ni ni-tv-2 text-primary",
        component: <CommingSoonModel />,
        layout: "/admin",
      },
      {
        path: "/etc",
        name: "Etc",
        icon: "ni ni-tv-2 text-primary",
        component: <CommingSoonModel />,
        layout: "/admin",
      },

    ]
  },

  {
    path: "/options",
    // name: "Options",
    icon: "ni ni-tv-2 text-primary",
    component: <CommingSoonModel />,
    layout: "/admin",
  },
  {
    path: "/etc",
    // name: "Etc",
    icon: "ni ni-tv-2 text-primary",
    component: <CommingSoonModel />,
    layout: "/admin",
  },

  {
    path: "/category",
    // name: "Categories",
    icon: "ni ni-tv-2 text-primary",
    component: <Category />,
    layout: "/admin",
  },

  {
    path: "/user",
    name: "Settings",
    icon: "ni ni-tv-2 text-primary",
    component: <UserIndex />,
    layout: "/admin",
    subMenu: [

      // {
      //   path: "/category",
      //   name: "Categories",
      //   icon: "ni ni-tv-2 text-primary",
      //   component: <Category />,
      //   layout: "/admin",
      // },
      {
        path: "/payment-methods",
        name: "Payment Methods",
        icon: "ni ni-tv-2 text-primary",
        component: <UserIndex />,
        layout: "/admin",
      },

      {
        path: "/invoice",
        name: "Invoice Settings",
        icon: "ni ni-tv-2 text-primary",
        component: <UserIndex />,
        layout: "/admin",
      },
      {
        path: "/invoice",
        name: "Notifications",
        icon: "ni ni-tv-2 text-primary",
        component: <UserIndex />,
        layout: "/admin",
        subMenu: [
          {
            path: "/email",
            name: "Emails",
            icon: "ni ni-tv-2 text-primary",
            component: <EmailTemplateView />,
            layout: "/admin",
          },
          {
            path: "/sms",
            name: "Sms",
            icon: "ni ni-tv-2 text-primary",
            component: <EmailTemplateView />,
            layout: "/admin",
          },
        ]
      },
      {
        path: "/restricted",
        name: "Restricted IP’s",
        icon: "ni ni-tv-2 text-primary",
        component: <UserIndex />,
        layout: "/admin",
      },
      {
        path: "/user",
        name: "Users",
        icon: "ni ni-tv-2 text-primary",
        component: <UserIndex />,
        layout: "/admin",
      },
      {
        path: "/marketing",
        name: " Marketing Options",
        icon: "ni ni-tv-2 text-primary",
        component: <UserIndex />,
        layout: "/admin",
      },
      {
        path: "/option",
        name: "Option",
        icon: "ni ni-tv-2 text-primary",
        component: <UserIndex />,
        layout: "/admin",
      },
      {
        path: "/purchase",
        name: "Purchase",
        icon: "ni ni-tv-2 text-primary",
        component: <UserIndex />,
        layout: "/admin",
      },
      {
        path: "/income",
        name: "Income",
        icon: "ni ni-tv-2 text-primary",
        component: <UserIndex />,
        layout: "/admin",
      },

    ]
  },


  {
    path: "/payment-methods",
    // name: "Payment Methods",
    icon: "ni ni-tv-2 text-primary",
    component: <CommingSoonModel />,
    layout: "/admin",
  },
  {
    path: "/emails",
    // name: "Emails",
    icon: "ni ni-tv-2 text-primary",
    component: <CommingSoonModel />,
    layout: "/admin",
  },
  {
    path: "/invoice",
    // name: "Invoice Settings",
    icon: "ni ni-tv-2 text-primary",
    component: <CommingSoonModel />,
    layout: "/admin",
  },
  {
    path: "/notifications",
    // name: "Notifications",
    icon: "ni ni-tv-2 text-primary",
    component: <CommingSoonModel />,
    layout: "/admin",
  },
  {
    path: "/restricted",
    // name: "Restricted IP’s",
    icon: "ni ni-tv-2 text-primary",
    component: <CommingSoonModel />,
    layout: "/admin",
  },
  {
    path: "/user",
    // name: "Users",
    icon: "ni ni-tv-2 text-primary",
    component: <CommingSoonModel />,
    layout: "/admin",
  },
  {
    path: "/marketing",
    // name: " Marketing Options",
    icon: "ni ni-tv-2 text-primary",
    component: <CommingSoonModel />,
    layout: "/admin",
  },
  {
    path: "/option",
    // name: "Option",
    icon: "ni ni-tv-2 text-primary",
    component: <CommingSoonModel />,
    layout: "/admin",
  },
  {
    path: "/purchase",
    // name: "Purchase",
    icon: "ni ni-tv-2 text-primary",
    component: <CommingSoonModel />,
    layout: "/admin",
  },
  {
    path: "/income",
    // name: "Income",
    icon: "ni ni-tv-2 text-primary",
    component: <CommingSoonModel />,
    layout: "/admin",
  },


  {
    path: "/user",
    name: "Reports",
    icon: "ni ni-tv-2 text-primary",
    component: <UserIndex />,
    layout: "/admin",
    subMenu: [
      {
        path: "/user",
        name: "Companies",
        icon: "ni ni-tv-2 text-primary",
        component: <UserIndex />,
        layout: "/admin",
      },
      {
        path: "/sales",
        name: "Sales",
        icon: "ni ni-tv-2 text-primary",
        component: <UserIndex />,
        layout: "/admin",
      },

    ]
  },

  {
    path: "/sales",
    // name: "Sales",
    icon: "ni ni-tv-2 text-primary",
    component: <CommingSoonModel />,
    layout: "/admin",
  },



  {
    path: "/email",
    name: "Others",
    icon: "ni ni-tv-2 text-primary",
    component: <EmailTemplateView />,
    layout: "/admin",
    subMenu: [
      // {
      //   path: "/email",
      //   name: "Email Template",
      //   icon: "ni ni-tv-2 text-primary",
      //   component: <EmailTemplateView />,
      //   layout: "/admin",
      // },

      {
        path: "/home-page",
        name: "Home Page Management",
        icon: "ni ni-tv-2 text-primary",
        component: <HomePage />,
        layout: "/admin",
      },

      {
        path: "/blog",
        name: "Article Management",
        icon: "ni ni-tv-2 text-primary",
        component: <Blog />,
        layout: "/admin",
      },
      {
        path: "/faq",
        name: "Faq Managemnt",
        icon: "ni ni-tv-2 text-primary",
        component: <Faq />,
        layout: "/admin",
      },
      {
        path: "/setting",
        name: "Global Management",
        icon: "ni ni-tv-2 text-primary",
        component: <Setting />,
        layout: "/admin",
      },
      {
        path: "/news-letter",
        name: "Newsletter Management",
        icon: "ni ni-tv-2 text-primary",
        component: <NewsLetter />,
        layout: "/admin",
      },
      {
        path: "/contact-us",
        name: "Contact Us Management",
        icon: "ni ni-tv-2 text-primary",
        component: <ContactUs />,
        layout: "/admin",
      },
    ]
  },



  {
    path: "/news-letter/edit",
    // name: "User Management",
    icon: "ni ni-tv-2 text-primary",
    component: <NewsLetterEdit />,
    layout: "/admin",
  },

  {
    path: "/contact-us",
    // name: "Newsletter Management",
    icon: "ni ni-tv-2 text-primary",
    component: <ContactUs />,
    layout: "/admin",
  },
  {
    path: "/contact-us/view",
    // name: "Newsletter Management",
    icon: "ni ni-tv-2 text-primary",
    component: <ContactUsView />,
    layout: "/admin",
  },


  {
    path: "/news-letter",
    // name: "Newsletter Management",
    icon: "ni ni-tv-2 text-primary",
    component: <NewsLetter />,
    layout: "/admin",
  },

  {
    path: "/news-letter/add",
    // name: "Newsletter Management",
    icon: "ni ni-tv-2 text-primary",
    component: <NewsLetterAdd />,
    layout: "/admin",
  },




  {
    path: "/email",
    // name: "Email Template",
    icon: "ni ni-tv-2 text-primary",
    component: <EmailTemplateView />,
    layout: "/admin",
  },

  {
    path: "/home-page",
    // name: "Home Page Management",
    icon: "ni ni-tv-2 text-primary",
    component: <HomePage />,
    layout: "/admin",
  },

  {
    path: "/blog",
    // name: "Article Management",
    icon: "ni ni-tv-2 text-primary",
    component: <Blog />,
    layout: "/admin",
  },
  {
    path: "/faq",
    // name: "Faq Managemnt",
    icon: "ni ni-tv-2 text-primary",
    component: <Faq />,
    layout: "/admin",
  },
  {
    path: "/setting",
    // name: "Global Management",
    icon: "ni ni-tv-2 text-primary",
    component: <Setting />,
    layout: "/admin",
  },





  {
    path: "/email/view",
    // name: "User Management",
    icon: "ni ni-tv-2 text-primary",
    component: <EmailTemplateViewOne />,
    layout: "/admin",
  },

  {
    path: "/email/edit",
    // name: "User Management",
    icon: "ni ni-tv-2 text-primary",
    component: <EmailTemplateEdit />,
    layout: "/admin",
  },

  {
    path: "/email/add",
    // name: "User Management",
    icon: "ni ni-tv-2 text-primary",
    component: <EmailTemplateAdd />,
    layout: "/admin",
  },

  {
    path: "/home-page/add",
    // name: "User Management",
    icon: "ni ni-tv-2 text-primary",
    component: <HomePageAdd />,
    layout: "/admin",
  },
  {
    path: "/home-page/edit",
    // name: "User Management",
    icon: "ni ni-tv-2 text-primary",
    component: <HomePageEdit />,
    layout: "/admin",
  },
  {
    path: "/home-page/view",
    // name: "User Management",
    icon: "ni ni-tv-2 text-primary",
    component: <HomePageView />,
    layout: "/admin",
  },


  {
    path: "/blog/add",
    // name: "User Management",
    icon: "ni ni-tv-2 text-primary",
    component: <BlogAdd />,
    layout: "/admin",
  },
  {
    path: "/blog/edit",
    // name: "User Management",
    icon: "ni ni-tv-2 text-primary",
    component: <BlogEdit />,
    layout: "/admin",
  },
  {
    path: "/blog/view",
    // name: "User Management",
    icon: "ni ni-tv-2 text-primary",
    component: <BlogView />,
    layout: "/admin",
  },


  {
    path: "/faq/add",
    // name: "Fag Managemnt",
    icon: "ni ni-tv-2 text-primary",
    component: <FaqAdd />,
    layout: "/admin",
  },
  {
    path: "/faq/edit",
    // name: "Fag Managemnt",
    icon: "ni ni-tv-2 text-primary",
    component: <FaqEdit />,
    layout: "/admin",
  },
  {
    path: "/faq/view",
    // name: "Fag Managemnt",
    icon: "ni ni-tv-2 text-primary",
    component: <FaqView />,
    layout: "/admin",
  },

  {
    path: "/change-password",
    // name: "Fag Managemnt",
    icon: "ni ni-tv-2 text-primary",
    component: <ChangePassword />,
    layout: "/admin",
  },



  // {
  //   path: "/user",
  //   name: "User Management",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: <UserIndex />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/user",
  //   name: "User Management",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: <UserIndex />,
  //   layout: "/admin",

  // },


  {
    path: "/user/add",
    // name: "User Management",
    icon: "ni ni-tv-2 text-primary",
    component: <UserAdd />,
    layout: "/admin",
  },
  {
    path: "/user/edit",
    // name: "User Management",
    icon: "ni ni-tv-2 text-primary",
    component: <UserEdit />,
    layout: "/admin",
  },
  {
    path: "/user/view",
    // name: "User Management",
    icon: "ni ni-tv-2 text-primary",
    component: <UserOne />,
    layout: "/admin",
  },

  {
    path: "/staff/add",
    // name: "User Management",
    icon: "ni ni-tv-2 text-primary",
    component: <StaffAdd />,
    layout: "/admin",
  },
  {
    path: "/staff/edit",
    // name: "User Management",
    icon: "ni ni-tv-2 text-primary",
    component: <StaffEdit />,
    layout: "/admin",
  },
  {
    path: "/staff/view",
    // name: "User Management",
    icon: "ni ni-tv-2 text-primary",
    component: <StaffOne />,
    layout: "/admin",
  },
  // {
  //   path: "/email",
  //   name: "Email Template",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: <EmailTemplateView />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/email/view",
  //   // name: "User Management",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: <EmailTemplateViewOne />,
  //   layout: "/admin",
  // },

  // {
  //   path: "/email/edit",
  //   // name: "User Management",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: <EmailTemplateEdit />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/home-page",
  //   name: "Home Page Management",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: <HomePage />,
  //   layout: "/admin",
  // },


  // {
  //   path: "/home-page/add",
  //   // name: "User Management",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: <HomePageAdd />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/home-page/edit",
  //   // name: "User Management",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: <HomePageEdit />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/home-page/view",
  //   // name: "User Management",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: <HomePageView />,
  //   layout: "/admin",
  // },

  // {
  //   path: "/blog",
  //   name: "Article Management",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: <Blog />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/blog/add",
  //   // name: "User Management",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: <BlogAdd />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/blog/edit",
  //   // name: "User Management",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: <BlogEdit />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/blog/view",
  //   // name: "User Management",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: <BlogView />,
  //   layout: "/admin",
  // },




  // {
  //   path: "/faq",
  //   name: "Faq Managemnt",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: <Faq />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/faq/add",
  //   // name: "Fag Managemnt",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: <FaqAdd />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/faq/edit",
  //   // name: "Fag Managemnt",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: <FaqEdit />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/faq/view",
  //   // name: "Fag Managemnt",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: <FaqView />,
  //   layout: "/admin",
  // },
  {
    path: "/profile",
    // name: "User Management",
    icon: "ni ni-tv-2 text-primary",
    component: <Profile />,
    layout: "/admin",
  },

  // {
  //   path: "/setting",
  //   name: "Global Management",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: <Setting />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/setting/add",
  //   name: "Setting Management",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: <Setting />,
  //   layout: "/admin",
  // },






  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-planet text-blue",
  //   component: <Icons />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "ni ni-pin-3 text-orange",
  //   component: <Maps />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/user-profile",
  //   name: "User Profile",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: <Profile />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/tables",
  //   name: "Tables",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: <Tables />,
  //   layout: "/admin",
  // },
  {
    path: "/login",
    // name: "Login",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
  },
  {
    path: "/register",
    // name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: <Register />,
    layout: "/auth",
  },
  {
    path: "/forgot-password",
    // name: "Forgot Password",
    icon: "ni ni-circle-08 text-pink",
    component: <ForgotPassword />,
    layout: "/auth",
  },
  {
    path: "/forgot-password-otp/:id/:password",
    // name: "Forgot Password Otp",
    icon: "ni ni-circle-08 text-pink",
    component: <ForgotPasswordOtp />,
    layout: "/auth",
  },
  {
    path: "/reset-password/:id",
    // name: "Reset Password",
    icon: "ni ni-circle-08 text-pink",
    component: <ResetPasswordOtp />,
    layout: "/auth",
  },

  {
    path: "/template/manage-field",
    // name: "Categories",
    icon: "ni ni-tv-2 text-primary",
    component: <ManageField />,
    layout: "/admin",
  },
  {
    path: "/template/manage-field/add",
    // name: "User Management",
    icon: "ni ni-tv-2 text-primary",
    component: <ManageFieldAdd />,
    layout: "/admin",
  },
  {
    path: "/template/manage-field/edit",
    // name: "User Management",
    icon: "ni ni-tv-2 text-primary",
    component: <ManageFieldEdit />,
    layout: "/admin",
  },

];
export default routes;
