import React, { useEffect, useState } from "react";

import {
  FormGroup,
  Form,

  InputGroup,
  Row,
  Col,
  Button,
  Card,
  CardHeader
} from "reactstrap";
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { get, post } from "../../utils/apiManager";
import { toast } from "react-toastify";
import { baseURL } from "../../config/config";
import SuccessModal from "../../components/SuccessModal";

const Setting = () => {

  const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm();
  const [id, setId] = useState("")
  const [modalData, setModalData] =  useState({
    message: "",
    show: false
  });

  useEffect(() => {
    getOneSetting()
  }, [])


  const getOneSetting = (id) => {
    get(`/global-setting`).then((res) => {
      if (res?.datas) {
        setValue("header", res?.datas?.header)
        setValue("telegram", res?.datas?.telegram)
        setValue("instagram", res?.datas?.instagram)
        setValue("facebook", res?.datas?.facebook)
        setValue("discord", res?.datas?.discord)
        setValue("twitter", res?.datas?.twitter)
        setValue("allowedPhoto", res?.datas?.allowedPhoto)

        setId(res?.datas?._id)

      }

    }).catch((error) => {
      console.log(error, "erre");
    })
  }

  const onSubmit = (data) => {

    const Url = `${baseURL}/global-setting/update`
    let newData = {}
    // console.log(id,"id")
    if (id != "") {
      newData = { id, header: data?.header, telegram: data?.telegram, instagram: data?.instagram, facebook: data?.facebook, discord: data?.discord, twitter: data?.twitter, allowedPhoto: data?.allowedPhoto }
    } else {
      newData = data
    }

    post(Url, newData).then((data) => {
      if (data?.status) {
        // toast.success(data?.message);
        setModalData({ 
          message: data?.message,
          show: true
        })
      }
      else {

        toast.error(data?.message)
      }
    }).catch(err => {
      toast.error(err.response.data.error)

    })

  }

  return (
    <>

      <Row className="mt-7">
        <Col className="mb-5 mb-xl-0 ml-5" xl="11">
          <Card className="shadow">
            <CardHeader className="border-0">
              <div className="d-flex">
                <h2 style={{ textAlign: "left", marginTop: "10px" }}>Global Setting</h2>
                {/* <Button
                  className='bi-trash justify-content-right'
                  style={{ position: "absolute", right: 0, marginRight: "40px" }}
                  onClick={() => Navigate(`/admin/faq`)}
                  color="primary"
                >
                  Back
                </Button> */}
              </div>
              <Form style={{ marginTop: "40px" }} onSubmit={handleSubmit(onSubmit)}>



                <Row>
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Header Content <span className="text-danger">*</span></label>
                      <InputGroup className="input-group-alternative">
                        <textarea
                          id="header"
                          name="header"
                          placeholder="Enter header"
                          {...register("header", { required: "This field is required" })}
                          className="mt-1 block w-full px-3 py-2 form-control border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"

                        />
                      </InputGroup>
                      {errors.header && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Instagram Url <span className="text-danger">*</span> </label>
                      <InputGroup className="input-group-alternative">
                        <input
                          id="instagram"
                          name="instagram"
                          placeholder="Enter instagram url"
                          {...register("instagram", { required: "This field is required" })}
                          className="mt-1 block w-full px-3 py-2 form-control border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"

                        />
                      </InputGroup>
                      {errors.instagram && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Facebook Url <span className="text-danger">*</span></label>
                      <InputGroup className="input-group-alternative">
                        <input
                          id="facebook"
                          name="facebook"
                          placeholder="Enter facebook url"
                          {...register("facebook", { required: "This field is required" })}
                          className="mt-1 block w-full px-3 py-2 form-control border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"

                        />
                      </InputGroup>
                      {errors.facebook && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Twitter Url <span className="text-danger">*</span></label>
                      <InputGroup className="input-group-alternative">
                        <input
                          id="twitter"
                          name="twitter"
                          placeholder="Enter twitter url"
                          {...register("twitter", { required: "This field is required" })}
                          className="mt-1 block w-full px-3 py-2 form-control border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"

                        />
                      </InputGroup>
                      {errors.twitter && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Discord Url <span className="text-danger">*</span></label>
                      <InputGroup className="input-group-alternative">
                        <input
                          id="discord"
                          name="discord"
                          placeholder="Enter discord url"
                          {...register("discord", { required: "This field is required" })}
                          className="mt-1 block w-full px-3 py-2 form-control border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"

                        />
                      </InputGroup>
                      {errors.discord && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Telegram Url <span className="text-danger">*</span></label>
                      <InputGroup className="input-group-alternative">
                        <input
                          id="telegram"
                          name="telegram"
                          placeholder="Enter telegram url"
                          {...register("telegram", { required: "This field is required" })}
                          className="mt-1 block w-full px-3 py-2 form-control border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"

                        />
                      </InputGroup>
                      {errors.telegram && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Photos allowed<span className="text-danger">*</span></label>
                      <InputGroup className="input-group-alternative">
                        <input
                          id="allowedPhoto"
                          name="allowedPhoto"
                          placeholder="Enter Allow Photo"
                          {...register("allowedPhoto", {
                            required: "This field is required", pattern: {
                              value: /^[0-9]+$/,
                              message: 'only numbers allowed'
                            },
                          })}
                          className="mt-1 block w-full px-3 py-2 form-control border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"

                        />
                      </InputGroup>
                      {errors.allowedPhoto && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  </Col>


                </Row>
                <Button color="primary" type="submit" className="d-flex">
                  Submit
                </Button>
              </Form>
            </CardHeader>
          </Card>
        </Col>
      </Row> 
      <SuccessModal message={modalData?.message} showModal={modalData?.show} setShowModal={setModalData} />

    </>
  );

}

export default Setting;