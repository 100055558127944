import React, { useEffect,useState } from "react";
// reactstrap components
import {
 
  Button,
  Row,
  Col,
  Card,
  CardHeader
} from "reactstrap";
import { useNavigate} from 'react-router-dom';
import { get } from "../../utils/apiManager";

import { useLocation } from 'react-router-dom';



const ViewOne = () =>  {

    const location = useLocation()
    const Navigate = useNavigate()
    const [homePage,setHomePage] = useState({})

    useEffect(()=>{
        if(location?.search){
           
            getOneHomePage(location?.search?.split("?")[1])
        }
    },[])


    const getOneHomePage = (id) => {
        get(`/home-page/${id}`).then((res) => {
           setHomePage(res?.datas)
            
          }).catch((error) => {
            console.log(error, "erre");
          })
    }



    


    return (
      <>
            {/* <Header /> */}
           
         <Row className="mt-7">
          <Col className="mb-5 mb-xl-0 ml-5" xl="11" >
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0" style={{textAlign:"left"}}>View Home Page</h3>
                    <Button  className='bi-trash justify-content-right' style={{position
                :"absolute",right:0,marginRight:"40px"}} onClick={() => Navigate(`/admin/home-page`)} color="primary">Back</Button>
                  </div>
                 
                </Row>
              </CardHeader>
                <div style={{textAlign:"left",marginLeft:"30px"}}>
                    <p>Page Name : {homePage?.title}</p><br />
                     <p>Name : {homePage?.name}</p><br />
                     <p>Title : {homePage?.title}</p><br />
                     <p>Sub Title : {homePage?.subtitle}</p><br />
                     <p>Description :   {homePage?.description != "undefined" ? <p dangerouslySetInnerHTML={{__html: homePage?.description}}/>:"-"}</p><br/>

                     <p>Status :{homePage?.isActive ? <Button color="success" type="submit" className="ml-2">
                                          Active
                             </Button> : <Button color="danger" type="submit" className="ml-2">
                                          Deactive
                             </Button>}</p><br />

                </div>
            </Card>
          </Col>
         
         
        </Row>
      </>
    );
  
}

export default ViewOne