import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";


const CommingSoonModel = () => {
    const [commingSoonModel, setCommingSoonModel] = useState(true)
    const Navigate = useNavigate()



    return (
        <Modal
            isOpen={commingSoonModel}
            onRequestClose={() => { setCommingSoonModel(false); Navigate("/admin/index") }}

            style={{
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    padding: '30px',
                    borderRadius: '10px',
                    maxWidth: '840px',
                    width: '100%',
                },
            }}
            contentLabel="Signup Form"
        >

            <div><img src="/assets/img/commingsoon.jpg" /></div>


        </Modal>
    )

}

export default CommingSoonModel