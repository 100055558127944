import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const Editor = ({ editorData, setEditorData }) => {

  return (
    <div>
      {/* <CKEditor
        editor={ClassicEditor}

        data={editorData}
        // onReady={editor => {
        //   console.log('Editor is ready to use!', editor);
        // }}
        onChange={(event, editor) => {
          const data = editor.getData();
          setEditorData(data);
          console.log({ event, editor, data });
        }}
        onBlur={(event, editor) => {
          console.log('Blur.', editor);
        }}
        onFocus={(event, editor) => {
          console.log('Focus.', editor);
        }}
        onReady={editor => {

          // List all loaded plugins
          console.log('Loaded plugins:', editor?.plugins);
        }}

      /> */}
      <CKEditor
        editor={ClassicEditor}
        data={editorData}
        config={{
          toolbar: [
            'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'undo', 'redo',
            'fontSize', 'fontColor', 'fontBackgroundColor', 'highlight', 'alignment', 'imageUpload', 'insertTable', 'mediaEmbed'
          ],
          fontSize: {
            options: [
              'tiny', 'small', 'default', 'big', 'huge',
              8, 10, 12, 14, 16, 18, 20, 24, 28, 32, 36, 40, 48
            ],
            supportAllValues: true // Allows users to enter custom values
          },
          language: 'en',
        }}
        onReady={editor => {
          console.log('Editor is ready to use!', editor);
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          setEditorData(data);
          console.log({ event, editor, data });
        }}
        onBlur={(event, editor) => {
          console.log('Blur.', editor);
        }}
        onFocus={(event, editor) => {
          console.log('Focus.', editor);
        }}
      />
    </div>
  );
}

export default Editor;
