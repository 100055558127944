
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  // InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { baseURL } from '../config/config';
import { post } from "../utils/apiManager";
import { ToastContainer, toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { LoginDetails } from "../reduxToolkit/slice/auth";
import { useDispatch, useSelector } from "react-redux";


const Login = () => {

  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const Navigate = useNavigate()
  const dispatch = useDispatch()


  const onSubmit = (data) => {



    const Url = `${baseURL}/admin/login`
    post(Url, data).then((data) => {
      if (data?.status) {
        toast.success(data?.message);
        // localStorage.setItem("token",data?.token)
        dispatch(LoginDetails(
          { token: data?.token, name: data?.data?.name, userId: data?.data?._id, email: data?.data?.email, isLogin: true, profileImage: data?.data?.profileImage }
        ))
        Navigate("/admin/index")
      }
      else {

        toast.error(data?.message)
      }
    }).catch(err => {
      toast.error(err.response.data.error)

    })

  }


  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">

          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Or sign in with credentials</small>
            </div>
            <Form role="form" onSubmit={handleSubmit(onSubmit)}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  {/* <InputGroupAddon addonType="prepend"> */}
                  <InputGroupText>
                    <i class="fa fa-envelope" aria-hidden="true"></i>

                  </InputGroupText>
                  {/* </InputGroupAddon> */}
                  <input
                    placeholder="Email"
                    type="email"
                    className="form-control"
                    autoComplete="new-email"
                    {...register("email", { required: true })}
                  />

                </InputGroup>
                {errors.email && <span className="text-danger d-flex"> This field is required</span>}
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  {/* <InputGroupAddon addonType="prepend"> */}
                  <InputGroupText>
                    <i class="fa fa-lock" aria-hidden="true"></i>

                  </InputGroupText>
                  {/* </InputGroupAddon> */}
                  <input
                    placeholder="Password"
                    type="password"
                    className="form-control"
                    name="password"
                    {...register("password", { required: true })}
                  />
                </InputGroup>
                {errors.password && <span className="text-danger d-flex"> This field is required</span>}

              </FormGroup>
              {/* <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Remember me</span>
                </label>
              </div> */}
              <div className="text-center">
                <Button className="my-4" color="primary" type="submit"
                  onClick={onSubmit}
                >
                  Sign in
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <Link
              className="text-light"
              to="/auth/forgot-password"
            // onClick={(e) => e.preventDefault()}
            >
              <small>Forgot password?</small>
            </Link>
          </Col>

        </Row>
      </Col>
    </>
  );
};

export default Login;
