import React, { useEffect, useState } from "react";

// reactstrap components
import {
  FormGroup,
  Form,

  InputGroup,
  Row,
  Col,
  Button,
  Card,
  CardHeader
} from "reactstrap";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { post, get } from "../../utils/apiManager";
import { toast } from "react-toastify";
import { baseURL } from "../../config/config";
import CKEditor from 'ckeditor4-react';
import Editor from "../../components/Editor";




const Add = () => {
  const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm();
  const Navigate = useNavigate()
  const [editorData, setEditorData] = useState()


  const onSubmit = (data) => {

    const Url = `${baseURL}/blog/add`
    if (editorData == "") {
      toast.error("description field is required")
      return
    }


    let formData = new FormData();

    formData.append("title", data?.title)
    formData.append("image", data?.image?.[0])
    formData.append("description", editorData ? editorData : "")

    post(Url, formData, 1).then((data) => {
      if (data?.status) {
        // toast.success(data?.message);
        Navigate("/admin/blog")

      }
      else {

        toast.error(data?.message)
      }
    }).catch(err => {
      toast.error(err.response.data.error)

    })

  }


  return (
    <>

      <Row className="mt-7" >
        <Col className="mb-5 mb-xl-0 ml-5" xl="11" >
          <Card className="shadow">
            <CardHeader className="border-0">
              <div className="d-flex">
                <h2 style={{ textAlign: "left", marginTop: "10px" }}>Article Add</h2>

                <Button className='bi-trash justify-content-right' style={{
                  position
                    : "absolute", right: 0, marginRight: "40px"
                }} onClick={() => Navigate(`/admin/blog`)} color="primary">Back</Button>
              </div>
              <Form className="" style={{ marginTop: "40px" }} onSubmit={handleSubmit(onSubmit)}>
                <Row>


                  <Col md="4">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Title <span className="text-danger">*</span></label>

                      <InputGroup className="input-group-alternative">


                        <input
                          placeholder="Title"
                          type="text"
                          autoComplete="new-email"
                          {...register("title", { required: true })}
                          className="form-control border"
                        />

                      </InputGroup>
                      {errors.title && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  </Col>

                  <Col md="4">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Image  <span className="text-danger">*</span></label>

                      <InputGroup className="input-group-alternative">

                        <input
                          placeholder="Please Select Image"
                          type="file"
                          {...register("image", { required: false })}
                          className="form-control border"
                        />

                      </InputGroup>
                      {errors.image && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <Editor editorData={editorData} setEditorData={setEditorData} />
                  </Col>
                </Row>

                <Button color="primary" type="submit" className="d-flex" style={{ marginTop: "10px" }} >
                  Submit
                </Button>

              </Form>
            </CardHeader>
          </Card>
        </Col>
      </Row>
    </>
  );

}

export default Add;