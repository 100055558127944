import React from 'react';
import { Button } from 'reactstrap';



const ExportCSV = ({ data }) => {
    const handleExport = () => {
        let exportsData = [{ categoryName: "categoryName", subCategoryName: "subCategoryName", description: "description" }]
        if (data.length > 0) {
            data?.map((item) => {
                exportsData.push({ "categoryName": item?.name, "subCategoryName": item?.subcategories?.name, description: item.description })
            })
        }

        const csv = convertToCSV(exportsData);
        downloadCSV(csv, 'categories.csv');
    };



    const convertToCSV = (array) => {
        const header = Object.keys(array[0]).join(',') + '\n';
        const rows = array.map(obj => Object.values(obj).join(',')).join('\n');
        return header + rows;
    };

    const downloadCSV = (csvContent, fileName) => {
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement("a");
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };


    return (

        <Button
            color="primary"
            onClick={handleExport}
        >
            Export CSV
        </Button>
    );
};

export default ExportCSV;
