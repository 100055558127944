import { createSlice } from '@reduxjs/toolkit'
const garmentShorting = createSlice({
    initialState: {
        service: "",
        item: '',
        brand: "",
        colors: [],
        picture: "",
        stainImage: [],
        stain: [],
        reason: "",
        itemStatus: "",
        washType: "",
        description: "",
    },
    name: 'garmentShorting',
    reducers: {
        setService: (state, action) => {
            console.log('action.payload ********** ', action?.payload)
            state.service = action.payload
        },
        setItem: (state, action) => {
            console.log('action.payload ********** ', action?.payload)
            state.item = action.payload
        },
        setBrand: (state, action) => {
            console.log('action.payload ********** ', action?.payload)
            state.brand = action.payload
        },
        setColors: (state, action) => {
            const findData = state.colors.find((value) => value == action.payload);
            if (findData) {
                let colorData = state.colors.filter((item) => item !== action.payload)
                state.colors = colorData
            }
            else {
                state.colors = [...state.colors, action.payload]
            }
            console.log("state.colors **** ", state.colors)
        },
        setPicture: (state, action) => {
            state.picture = action.payload
        },
        setStainImage: (state, action) => {
            state.stainImage = [...state.stainImage, action.payload]
        },
        setReason: (state, action) => {
            console.log('action.payload ********** ', action?.payload)
            state.reason = action.payload
        },
        setStain: (state, action) => {
            const findData = state.stain.find((value) => value == action.payload);

            console.log(findData)
            if (findData) {
                let stainData = state.stain.filter((item) => item !== action.payload)
                state.stain = stainData
            }
            else {
                state.stain = [...state.stain, action.payload]
            }
        },
        setItemStatus: (state, action) => {
            console.log('action.payload ********** ', action?.payload)
            state.itemStatus = action.payload
        },
        setWashType: (state, action) => {
            console.log('action.payload ********** ', action?.payload)
            state.washType = action.payload
        },
        setDescription: (state, action) => {
            state.description = action.payload
        },
        setProcess: (state, action) => {
            state.description = action.payload
        },
        clearGarmentData: (state, action) => {
            state.service = "";
            state.item = '';
            state.brand = "";
            state.colors = [];
            state.picture = "";
            state.stainImage = [];
            state.stain = [];
            state.reason = "";
            state.itemStatus = "";
            state.washType = "";
            state.description = "";
        }
    }
});

export const { setService, setBrand, setColors, setItem, setItemStatus, setPicture, setReason, setStain, setStainImage, setWashType, setDescription, clearGarmentData } = garmentShorting.actions;
export default garmentShorting.reducer
export const garmentState = (state) => state.garmentShorting;




