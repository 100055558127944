

import React, { useEffect } from "react";
import { useLocation, Route, Routes, Navigate, Router } from "react-router-dom";
// reactstrap components
import { Container, Row } from "reactstrap";
// core components
import AdminNavbar from "../components/Navbars/AdminNavbar.js";
import AdminFooter from "../components/Footers/AdminFooter.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { baseURL } from '../config/config';
import { post } from "../utils/apiManager";

import routes from "../routes.js";
import { LoginDetails } from "../reduxToolkit/slice/auth.js";

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const { isLogin, token } = useSelector(state => state.auth);
  // const token = localStorage.getItem("token")

  const Navigate = useNavigate()
  const dispatch = useDispatch()

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  useEffect(() => {
    let tokenCheck = localStorage.getItem("token")
    if (tokenCheck != "null" && tokenCheck != "undefined") {
      let data = { token: tokenCheck }
      const Url = `${baseURL}/admin/check-token`
      post(Url, data).then((data) => checkToken(data));
      const checkToken = (data) => {
        // localStorage.setItem("token", data?.token)
        dispatch(LoginDetails(
          { token: data?.token, name: data?.data?.name, userId: data?.data?._id, email: data?.data?.email, isLogin: true, profileImage: data?.data?.profileImage }
        ))
        if (props.layout == "/auth" && tokenCheck) {
          Navigate("/admin/index")
        } else if (props.layout == "/admin" && !tokenCheck) {
          Navigate("/auth/login")
        }
      }
    } else {
      // console.log("running", routes, location.pathname.split("/")[1])
      // console.log(props.layout, "running")
      if (location.pathname.split("/")[1] == "auth" && tokenCheck) {
        Navigate("/admin/index")
      } else if (location.pathname.split("/")[1] == "admin" && tokenCheck === "null") {
        Navigate("/auth/login")
      }
    }
  }, [token])


  // useEffect(() => {
  //   if (!isLogin) {
  //     Navigate("/auth/login")
  //   }
  // }, [isLogin])

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route path={prop.path} element={prop.component} key={key} exact />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes?.length; i++) {
      if (
        props?.location?.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin/index",
          // imgSrc: require("../assets/img/brand/argon-react.png"),
          imgSrc: require("../assets/img/updated-logo.png"),

          imgAlt: "...",
        }}
      />

      <div className="main-content" ref={mainContent}>
        <Row>
          <AdminNavbar
            {...props}
            brandText={getBrandText(props?.location?.pathname)}
          />
        </Row>
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/admin/index" replace />} />
        </Routes>
        <Container fluid>
          {/* <AdminFooter /> */}
        </Container>
      </div>
    </>
  );
};

export default Admin;
