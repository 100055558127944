import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import AdminLayout from "../src/layouts/Admin";
import AuthLayout from "../src/layouts/Auth";
import { useDispatch, useSelector } from "react-redux";
import { baseURL } from "./config/config";
import { LoginDetails } from "./reduxToolkit/slice/auth.js";
import { post } from "./utils/apiManager";

const AppRoute = () => {
  const { isLogin, token } = useSelector(state => state.auth);
  const dispatch = useDispatch()
  let tokenCheckFirst = localStorage.getItem("token")

  useEffect(() => {
    if (token == null) {
      let tokenCheck = localStorage.getItem("token")
      if (tokenCheck != "null" && tokenCheck != "undefined") {
        let data = { token: tokenCheck }
        const Url = `${baseURL}/admin/check-token`
        post(Url, data).then((data) => checkToken(data));
        const checkToken = (data) => {
          // localStorage.setItem("token", data?.token)
          dispatch(LoginDetails(
            { token: data?.token, name: data?.data?.name, userId: data?.data?._id, email: data?.data?.email, isLogin: true, profileImage: data?.data?.profileImage }
          ))
          // Navigate("/admin/index")
        }
      }
    }
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/admin/*" element={<AdminLayout />} />
        <Route path="/auth/*" element={<AuthLayout />} />
        <Route path="*" element={<Navigate to="/auth/login" replace />} />
      </Routes>
    </BrowserRouter>
  )
}


export default AppRoute;